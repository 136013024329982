/* eslint-disable no-nested-ternary */
import 'react-grid-layout/css/styles.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-resizable/css/styles.css';
import './TicketToPrint.css';

import _ from 'lodash';
import { useEffect, useState } from 'react';
import Barcode from 'react-barcode';
import { Responsive, WidthProvider } from 'react-grid-layout';

import { DEFAULT_BARCODE_HEIGHT } from '../../../constants/default';
import { formatStringToFloat } from '../../../utils/formatStringToFloat';
import { defaultPrintProperties, getFromLS, getPrintableProductValue, widthConverter } from '../../../utils/helpers';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const TicketToPrint = ({ layoutWidthSlider = localStorage.getItem('ticket_width'), item, ...props }) => {
	const [layouts, setLayouts] = useState({});
	const [compactType] = useState('vertical');
	const [mounted, setMounted] = useState(false);

	const fontSize = localStorage.getItem('fontSize') || '14';

	const itemUnit = item?.MeasurementUnit?.toLowerCase(); // ML, L, G, KG
	const itemWeight = item?.Name?.toLowerCase()?.match(/(\d+(?:\.\d+)?)(g|kg|ltr|l|ml)/)?.[0]; // 100ML, 240ML, 50G
	let totalPrice = item?.Price;

	if (itemWeight) {
		if (itemUnit === 'kg' || itemUnit === 'l') {
			const separatedWeight = itemWeight?.match(/(\d+(?:\.\d+)?)/)?.[0];
			const separatedUnit = itemWeight?.match(/(g|kg|ltr|l|ml)/)?.[0];

			if (separatedUnit === 'ml' || separatedUnit === 'g') {
				// const unitPrice = parseFloat(item?.Price) / 1000;
				// totalPrice = (unitPrice * separatedWeight).toFixed(2)?.toString();
				const unitPrice = parseFloat(item?.Price) / separatedWeight;
				totalPrice = (unitPrice * 1000).toFixed(2)?.toString();
			}

			if (separatedUnit === 'l' || separatedUnit === 'kg') {
				const unitPrice = parseFloat(item?.Price) / 1000;
				totalPrice = (unitPrice * (separatedWeight * 1000)).toFixed(2)?.toString();
			}
		}
	}

	// set initial layout
	useEffect(() => {
		setLayouts(getFromLS('layouts')?.lg?.length > 0 ? getFromLS('layouts') : defaultPrintProperties);
	}, []);

	useEffect(() => {
		setMounted(true);
	}, []);

	const generateDOM = () => {
		// eslint-disable-next-line func-names
		return _.map(layouts.lg, function (l, i) {
			return (
				// eslint-disable-next-line jsx-a11y/no-static-element-interactions
				<div
					key={i}
					className={l.static ? 'static' : ''}
					onMouseDown={(e) => {
						e.stopPropagation();
					}}
				>
					<div
						className="printable-product-container"
						style={{
							// 🟢 DONE: Added the Price and UnitPrice to the right side of the ticket
							justifyContent: l.title === 'Price' || l.title === 'UnitPrice' ? 'flex-end' : 'flex-start',
						}}
					>
						{l.title === 'Barcode' ? (
							<div className="">
								{/* <Barcode value={item?.Barcode?.split('|')[0]?.trim()} displayValue={false} height={70} /> */}
								<div className="d-flex flex-column">
									<span style={{ marginBottom: '-4px', fontSize: '12px', textAlign: 'left' }}>
										{item?.Barcode?.[0]}
									</span>

									<Barcode
										value={
											Array.isArray(item?.Barcode)
												? item?.Barcode?.length >= 1
													? item?.Barcode?.[0]
													: item?.Barcode?.[0]
												: item?.Barcode
										}
										displayValue={false}
										height={parseInt(l?.height || DEFAULT_BARCODE_HEIGHT, 10)}
										fontSize={parseInt(l?.fontSize || fontSize, 10)}
										width={1}
										marginLeft={-1}
									/>
								</div>
							</div>
						) : l.title === 'Name' ? (
							<div
								className="h6 pt-0"
								style={{
									fontSize: `${1.5 * Number(l?.fontSize || fontSize)}px`,
								}}
							>
								{getPrintableProductValue(item, l.title)}
							</div>
						) : l.title === 'Price' ? (
							<div className="d-flex " style={{}}>
								<h1 className="display-3 fw-bold" style={{ fontSize: `${2.5 * Number(l?.fontSize || fontSize)}px` }}>
									{item?.Price?.toString()?.split('.')[0]}
								</h1>

								<div className="d-flex flex-column mt-2">
									<span
										className="h3 fw-bold"
										style={{
											fontSize: `${1.3 * Number(l?.fontSize || fontSize)}px`,
											padding: 0,
											margin: 0,
											marginBottom: '-4px',
											marginTop: '-2px',
										}}
									>
										€
									</span>
									<span
										className="h3 fw-bold"
										style={{
											fontSize: `${1.3 * Number(l?.fontSize || fontSize)}px`,
											padding: 0,
											margin: 0,
											marginTop: '-2px',
										}}
									>
										{formatStringToFloat(item?.Price?.toString())?.split('.')[1] || '00'}
									</span>
								</div>
							</div>
						) : l.title === 'UnitPrice' ? (
							<span
								style={{
									fontSize: `${1 * Number(l?.fontSize || fontSize)}px`,
									paddingTop: '5px',
									fontWeight: 'bold',
								}}
								className=""
							>
								€{totalPrice} / {itemUnit?.toUpperCase()}
							</span>
						) : l.title === 'CustomLabel' ? (
							<span
								style={{
									fontSize: `${1 * Number(l?.fontSize || fontSize)}px`,
									paddingTop: '5px',
								}}
								className="fw-bold"
							>
								{l?.value || ''}
							</span>
						) : l.title === 'Promo' ? (
							<span
								style={{
									fontSize: `${1 * Number(l?.fontSize || fontSize)}px`,
									paddingTop: '5px',
								}}
								className="fw-bold text-danger"
							>
								Promo
							</span>
						) : (
							<span
								style={{
									fontSize: `${1 * Number(l?.fontSize || fontSize)}px`,
									paddingTop: '5px',
									fontWeight: 'bold',
								}}
								className=""
							>
								{getPrintableProductValue(item, l.title)}
							</span>
						)}
					</div>
				</div>
			);
		});
	};

	return (
		<div
			className="printable-main template-main-container container-fluid"
			style={{ marginTop: '3px', marginBottom: '3px' }}
		>
			<div className="container d-flex flex-column align-items-center">
				<div
					style={{
						minWidth: '21cm',
						maxWidth: '21cm',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<div
						// className="printable-ticket-container border overflow-hidden"
						className="printable-ticket-container overflow-hidden"
						style={{
							width: `${widthConverter(layoutWidthSlider)}cm`,
							padding: '0',
						}}
					>
						<ResponsiveReactGridLayout
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...props}
							style={{ background: 'transparent' }}
							layouts={layouts}
							measureBeforeMount={false}
							useCSSTransforms={mounted}
							compactType={compactType}
							preventCollision={!compactType}
							// onBreakpointChange={onBreakpointChange}
							isDroppable
						>
							{generateDOM()}
						</ResponsiveReactGridLayout>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TicketToPrint;

TicketToPrint.defaultProps = {
	className: 'layout',
	rowHeight: 15,
	isDraggable: false,
	isResizable: false,
	// onLayoutChange: (layout, layouts) => {},
	cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
	breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
	containerPadding: [0, 0],
	margin: [0, 0],
};
