import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FileUploader } from 'react-drag-drop-files';
import { useMutation, useQuery } from 'react-query';

import { getBarcodeList, getProducts, getSkuList, submitBatchProduct } from '../../apis/productsAPIs';
import useDebounce from '../../hooks/useDebounce';
import initialValuesProduct from '../../pages/UserHomePage/validation/initialValues';
import { validationSchemaProduct } from '../../pages/UserHomePage/validation/validationSchema';
import { productFormInputs } from '../../utils/initialProducts';
import isEmpty from '../../utils/isEmpty';
import Error from '../atoms/Error';
import InputField from '../atoms/InputField';
import TextAreaInput from '../atoms/TextAreaInput';
import SearchableSelectInput from '../molecules/SearchableSelectInput';
import DeleteModal from './DeleteModal';

const extraDummyDelay = 3000;

const ProductsModal = ({
	showModal = false,
	handleClose,
	modalData = {},
	// eslint-disable-next-line no-unused-vars
	dataChangeType = '',
	submitFormHandler,
	isLoading = false,
	isError = false,
	error = null,
}) => {
	const [sku, setSku] = useState('');
	const [skuListOptions, setSkuListOptions] = useState([]);
	const [barcode, setBarcode] = useState('');
	const [barcodeListOptions, setBarcodeListOptions] = useState([]);
	const debouncedQuery = useDebounce(barcode, 500);
	// eslint-disable-next-line no-unused-vars
	const [activeTab, setActiveTab] = useState('single-product');
	const [showBatchUploadModal, setShowBatchUploadModal] = useState(false);
	const [batchUploadComplete, setBatchUploadComplete] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [page, setPage] = useState(1);
	// eslint-disable-next-line no-unused-vars
	const [searchQuery, setSearchQuery] = useState('');
	// eslint-disable-next-line no-unused-vars
	const [filterValues, setFilterValues] = useState([]);
	const [file, setFile] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [data, setData] = useState([]);

	const { data: skuListData, refetch: refetchSkuList } = useQuery(
		['sku-list'],
		() => getSkuList({ sku, page: 1, limit: 10 }),
		{
			enabled: true,
		}
	);

	const {
		data: barcodeListData,
		refetch: refetchBarcodeList,
		isLoading: barcodeLoading,
	} = useQuery(
		['barcode-list', debouncedQuery],
		() => getBarcodeList({ barcode: debouncedQuery, page: 1, limit: 10 }),
		{
			enabled: !!debouncedQuery,
		}
	);

	const {
		isError: isErrorSubmitBatchProduct,
		mutateAsync: mutateAsyncBatchProduct,
		// eslint-disable-next-line no-unused-vars
		error: errorSubmitBatchProduct,
	} = useMutation((data) => submitBatchProduct(data));

	const {
		// eslint-disable-next-line no-unused-vars
		data: productsData,
		// eslint-disable-next-line no-unused-vars
		isLoading: isLoadingProduct,
		refetch: refetchProducts,
		// eslint-disable-next-line no-unused-vars
		isFetched: isFetchedProducts,
	} = useQuery(['products'], () => getProducts({ page, searchQuery, filterValues }), {
		refetchOnWindowFocus: false,
		enabled: false,
	});

	useEffect(() => {
		if (sku) {
			refetchSkuList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sku]);

	useEffect(() => {
		if (barcode) {
			refetchBarcodeList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [barcode]);

	useEffect(() => {
		if (skuListData?.data) {
			const options = skuListData?.data.map((item) => ({
				key: item?.id,
				value: item?.SKU,
				label: item?.Name,
			}));
			setSkuListOptions(options);
		}
	}, [skuListData?.data]);

	useEffect(() => {
		if (barcodeListData?.data) {
			const options = barcodeListData?.data.map((item) => ({
				key: item?.id,
				value: item?.Barcode,
				label: item?.Name,
			}));
			setBarcodeListOptions(options);
		}
	}, [barcodeListData?.data]);

	const closeProductModal = () => {
		localStorage.removeItem('barcode');
		handleClose();
	};

	const handleFileUpload = async (inputFile) => {
		setShowBatchUploadModal(true);

		if (inputFile) {
			setFile(inputFile);

			const response = await mutateAsyncBatchProduct(inputFile);
			const approximateTime = response?.data?.approximateTime;

			// eslint-disable-next-line no-promise-executor-return
			await new Promise((r) => setTimeout(r, approximateTime + extraDummyDelay));

			// if success
			if (!isErrorSubmitBatchProduct) {
				setBatchUploadComplete(true);
				// eslint-disable-next-line no-promise-executor-return
				await new Promise((r) => setTimeout(r, extraDummyDelay)); // wait before closing

				setShowBatchUploadModal(false);
				refetchProducts(); // refetch the products
				closeProductModal();
			}
		}
	};

	useEffect(() => {
		if (file) {
			localStorage.setItem('last-modified-date', JSON.stringify(file?.lastModifiedDate));
		}
	}, [data, file]);

	return (
		<>
			<Modal show={showModal} onHide={closeProductModal} keyboard={false} centered className="modal-custom">
				<Modal.Header closeButton>
					<Modal.Title className="p-1 px-3">{dataChangeType} Product</Modal.Title>
				</Modal.Header>

				{dataChangeType?.toLowerCase() === 'add' && (
					<Modal.Body
						style={{
							maxHeight: '70vh',
							overflow: 'hidden',
							overflowY: 'auto',
							padding: '30px',
						}}
					>
						<Tabs defaultActiveKey={activeTab} fill variant="pills">
							<Tab eventKey="single-product" title="Add Single Product" style={{ padding: '10px 0px 0px 0px' }}>
								<Formik
									initialValues={initialValuesProduct(modalData)}
									validationSchema={validationSchemaProduct}
									onSubmit={submitFormHandler}
									enableReinitialize
								>
									{({ handleSubmit }) => (
										<Form className="row mt-1">
											{!isEmpty(modalData) &&
												productFormInputs?.map((item) => {
													if (item.type === 'textarea') {
														return (
															<TextAreaInput
																key={item?.name}
																label={item?.name}
																name={item.name}
																placeholder={item.placeholder || `Please enter ${item.name}`}
															/>
														);
													}

													if (item?.name === 'SKU') {
														return (
															<SearchableSelectInput
																key={item?.name}
																label={item?.name}
																name={item.name}
																placeholder={item.placeholder || `Please enter ${item.name}`}
																setFieldState={setSku}
																options={skuListOptions}
															/>
														);
													}

													if (item?.name === 'Barcode') {
														return (
															<SearchableSelectInput
																isMulti
																key={item?.name}
																label={item?.name}
																name={item.name}
																placeholder={item.placeholder || `Please enter ${item.name}`}
																setFieldState={setBarcode}
																options={barcodeListOptions}
																isLoading={barcodeLoading}
															/>
														);
													}

													return (
														<InputField
															key={item?.name}
															label={item.name}
															type={item.type}
															name={item.name}
															placeholder={item.placeholder || `Please enter ${item.name}`}
														/>
													);
												})}

											<div className="">
												{isError && (
													<div className="w-100">
														<Error error={error} />
													</div>
												)}

												<div className="d-flex justify-content-end align-items-end gap-3 mt-3">
													<Button
														style={{ minWidth: '120px' }}
														disabled={isLoading}
														type="button"
														variant="secondary"
														onClick={closeProductModal}
													>
														Close
													</Button>

													<Button
														style={{ minWidth: '120px' }}
														disabled={isLoading}
														type="submit"
														variant="primary"
														onClick={handleSubmit}
													>
														Save
													</Button>
												</div>
											</div>
										</Form>
									)}
								</Formik>
							</Tab>

							<Tab eventKey="multiple-product" title="Upload Bulk Products">
								<div className="container mt-5">
									<div className="row" style={{ minHeight: '60vh' }}>
										<div className="col py-4">
											<FileUploader
												classes="mw-100"
												label="Upload or drop a CSV file right here"
												multiple={false}
												handleChange={handleFileUpload}
												name="file"
												types={['CSV']}
												maxSize={10}
												required
											/>

											<p className="pt-3 text-center text-secondary">
												{file ? `File name: ${file?.name}` : 'No file uploaded, Please upload a CSV file here'}
											</p>
										</div>
									</div>
								</div>
							</Tab>
						</Tabs>
					</Modal.Body>
				)}

				{dataChangeType?.toLowerCase() === 'edit' && (
					<Modal.Body
						style={{
							maxHeight: '70vh',
							overflow: 'hidden',
							overflowY: 'auto',
							padding: '30px',
						}}
					>
						<Formik
							initialValues={initialValuesProduct(modalData)}
							validationSchema={validationSchemaProduct}
							onSubmit={submitFormHandler}
							enableReinitialize
						>
							{({ handleSubmit }) => (
								<Form className="row mt-1">
									{!isEmpty(modalData) &&
										productFormInputs?.map((item) => {
											if (item.type === 'textarea') {
												return (
													<TextAreaInput
														key={item?.name}
														label={item?.name}
														name={item.name}
														placeholder={item.placeholder || `Please enter ${item.name}`}
													/>
												);
											}

											if (item?.name === 'SKU') {
												return (
													<SearchableSelectInput
														key={item?.name}
														label={item?.name}
														name={item.name}
														placeholder={item.placeholder || `Please enter ${item.name}`}
														setFieldState={setSku}
														options={skuListOptions}
													/>
												);
											}

											if (item?.name === 'Barcode') {
												return (
													<SearchableSelectInput
														isMulti
														key={item?.name}
														label={item?.name}
														name={item.name}
														placeholder={item.placeholder || `Please enter ${item.name}`}
														setFieldState={setBarcode}
														options={barcodeListOptions}
														isLoading={barcodeLoading}
													/>
												);
											}

											/* 
                        if (item?.name === 'Weight') {
                          return (
                            <>
                              <div className="col-md-9">
                                <InputField
                                  key={item?.name}
                                  label={item.name}
                                  type={item.type}
                                  name={item.name}
                                  placeholder={item.placeholder || `Please enter ${item.name}`}
                                />
                              </div>

                              <div className="col-md-3" style={{ marginTop: '28px' }}>
                                <select className="form-select border-none py-2" aria-label="Select Product Unit Type">
                                  <option selected>Select Unit</option>
                                  <option value="MG">Milligram - MG</option>
                                  <option value="G">Gram - G</option>
                                  <option value="KG">Kilogram - KG</option>
                                  <option value="ML">Milliliter - ML</option>
                                  <option value="L">Liter - L</option>
                                </select>
                              </div>
                            </>
                          );
                        }   
                       */

											return (
												<InputField
													key={item?.name}
													label={item.name}
													type={item.type}
													name={item.name}
													placeholder={item.placeholder || `Please enter ${item.name}`}
												/>
											);
										})}

									<div className="">
										{isError && (
											<div className="w-100">
												<Error error={error} />
											</div>
										)}

										<div className="d-flex justify-content-end align-items-end gap-3 mt-3">
											<Button
												style={{ minWidth: '120px' }}
												disabled={isLoading}
												type="button"
												variant="secondary"
												onClick={closeProductModal}
											>
												Close
											</Button>

											<Button
												style={{ minWidth: '120px' }}
												disabled={isLoading}
												type="submit"
												variant="primary"
												onClick={handleSubmit}
											>
												Save
											</Button>
										</div>
									</div>
								</Form>
							)}
						</Formik>
					</Modal.Body>
				)}
			</Modal>

			{/* upload progress modal */}
			<DeleteModal showDeleteModal={showBatchUploadModal} hideFooter>
				<div
					className="pt-5"
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{!isErrorSubmitBatchProduct && (
						<>
							<div className={`circle-loader ${batchUploadComplete ? 'load-complete' : ''}`}>
								<div className={`checkmark draw ${batchUploadComplete ? 'show' : ''}`} />
							</div>
							<p
								style={{
									fontSize: '24px',
									marginTop: '-20px',
									color: '#6c757d',
								}}
							>
								{batchUploadComplete ? 'Upload Complete, please wait' : 'Uploading file, please wait'}
							</p>
						</>
					)}

					<p className="pb-3 text-center text-secondary">
						{file ? `File name: ${file?.name}` : 'No file uploaded, Please upload a CSV file here'}
					</p>

					{isErrorSubmitBatchProduct && (
						<div className="w-100">
							<Error error={errorSubmitBatchProduct} />
						</div>
					)}
				</div>
			</DeleteModal>
		</>
	);
};

export default ProductsModal;
