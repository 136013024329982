const UserIcon = ({ height = 16, width = 16, className = '' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 16 16"
			className={className}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="8" cy="5" r="3.5" stroke="#23262F" strokeWidth="1.5" />
			<path
				d="M8 10.5C11.3137 10.5 14 12.4183 14 16H2C2 12.4183 4.68629 10.5 8 10.5Z"
				stroke="#23262F"
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export default UserIcon;
