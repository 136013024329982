import { Suspense, useEffect, useState } from 'react';

import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import useCurrentUserRole from '../hooks/useCurrentUserRole';
import { routeConfig } from '../routes/config';
import { routes } from '../routes/routes';
import { ROLE_ADMIN, ROLE_USER } from '../utils/roles';

const BaseLayout = () => {
	const [applicationRoutes, setApplicationRoutes] = useState(routes);

	const { state: currentUserRole } = useCurrentUserRole();

	useEffect(() => {
		if (currentUserRole === ROLE_USER || currentUserRole === ROLE_ADMIN) {
			setApplicationRoutes(routes?.filter((route) => route?.roles?.includes(currentUserRole)));
		}
	}, [currentUserRole]);

	return (
		<>
			<Navigation />
			<main style={{ minHeight: 'calc(100vh-160px)' }}>
				<Suspense fallback={<div>Loading...</div>}>{routeConfig(applicationRoutes)}</Suspense>
			</main>
			<Footer />
		</>
	);
};

export default BaseLayout;
