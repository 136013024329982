const NotFound = () => {
	return (
		<div className="d-flex" style={{ minHeight: '85vh' }}>
			<div className="m-auto text-center align-self-center">
				<h1 className="display-1">404</h1>
				<p className="lead">Page Not Found</p>
				<p>The page you are looking for does not exist.</p>

				<a
					href="/"
					className="mt-6 inline-block px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700"
				>
					<button
						className="rounded-2 py-2 text-white px-4"
						type="submit"
						style={{
							fontWeight: '600',
							background: '#1CC5DC',
							border: 'none',
						}}
					>
						Back to home
					</button>
				</a>
			</div>
		</div>
	);
};

export default NotFound;
