/* eslint-disable react/require-default-props */
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

const HideIcon = ({ width = '18', height = '18', className = '' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox={`0 0 ${width} ${height}`}
			className={className}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.32266 10.7751C6.89141 10.3446 6.62891 9.75957 6.62891 9.10332C6.62891 7.78857 7.68791 6.72882 9.00191 6.72882C9.65216 6.72882 10.2507 6.99207 10.6744 7.42257"
				stroke="#23262F"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11.3285 9.52417C11.1545 10.4917 10.3925 11.2552 9.42578 11.4307"
				stroke="#23262F"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4.99125 13.1042C3.801 12.1697 2.793 10.8047 2.0625 9.10298C2.8005 7.39373 3.81525 6.02123 5.013 5.07923C6.20325 4.13723 7.5765 3.62573 9 3.62573C10.4318 3.62573 11.8043 4.14473 13.002 5.09348"
				stroke="#23262F"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.5866 6.7431C15.1026 7.4286 15.5563 8.21985 15.9381 9.1026C14.4628 12.5204 11.8558 14.5791 9.00056 14.5791C8.35331 14.5791 7.71506 14.4741 7.10156 14.2694"
				stroke="#23262F"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.9164 3.18719L3.08594 15.0177"
				stroke="#23262F"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
HideIcon.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
	className: PropTypes.string,
};
export default HideIcon;
