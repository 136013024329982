import { Button, Modal } from 'react-bootstrap';

import Error from './atoms/Error';

const ModalReuseable = ({ title, children, isError, error, showModal, isLoading, handleClose, handleSubmit }) => {
	return (
		<Modal
			show={showModal}
			onHide={handleClose}
			keyboard={false}
			backdrop="static"
			size="sm"
			centered
			className="modal-custom-filter"
		>
			<Modal.Header closeButton>
				<Modal.Title className="p-1 px-3 text-center w-100">{title}</Modal.Title>
			</Modal.Header>

			<Modal.Body
				style={{
					maxHeight: '70vh',
					overflow: 'hidden',
					overflowY: 'auto',
					padding: '30px',
				}}
			>
				{children}
			</Modal.Body>

			<Modal.Footer>
				{isError && (
					<div className="w-100">
						<Error error={error} />
					</div>
				)}

				<Button disabled={isLoading} type="button" variant="secondary" onClick={handleClose}>
					Close
				</Button>

				<Button disabled={isLoading} type="submit" variant="primary" onClick={handleSubmit}>
					Save
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalReuseable;
