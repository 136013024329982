import NotFound from '../pages/404/NotFound';
import Home from '../pages/Home';
import SignInPage from '../pages/SignIn/SignInPage';
import Register from '../pages/SignUp/SignUp';
import TicketTemplatePage from '../pages/TicketTemplatePage/TicketTemplatePage';
import UserLists from '../pages/Users/UserLists';
import { ROLE_ADMIN, ROLE_USER } from '../utils/roles';

export const authenticationRoutes = [
	{
		id: 'd5277041-70e5-49d4-aa35-0320546e6652',
		name: 'Home',
		path: '/',
		component: Home,
		exact: true,
		protected: true,
		roles: [ROLE_USER],
	},
	{
		id: 'k343n4j-70e5-49d4-aa35-0320546e6269',
		name: 'Home',
		path: '/ticket-template',
		component: TicketTemplatePage,
		exact: true,
		protected: true,
		roles: [ROLE_USER],
	},
	{
		id: '76ee4503-ed62-49cc-8050-1ae2b648983a',
		name: 'SignIn',
		path: '/signin',
		component: SignInPage,
		exact: true,
		protected: false,
	},
	{
		id: '76ee4503-ed62-49cc-8050-1ae2b64898t3',
		name: '404',
		path: '/*',
		component: NotFound,
		exact: true,
		protected: false,
	},
	{
		id: '2044728a-72ee-4316-afab-b085e9bd5337',
		name: 'Register',
		path: '/signup',
		component: Register,
		exact: true,
		protected: false,
	},
	{
		id: '2044728a-72ee-4316-afab-b085e9bd5338',
		name: 'Users',
		path: '/users',
		component: UserLists,
		exact: true,
		protected: true,
		roles: [ROLE_ADMIN],
	},
];

export const routes = [...authenticationRoutes];

export const authPaths = authenticationRoutes.map((route) => route.path);

export const authRoleAndPaths = routes
	.filter((e) => e.roles)
	.map((role) => ({
		role: role.roles,
		path: role.path,
	}));

export default routes;
