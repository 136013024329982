import { getStoredData } from '../utils/localStorage';
import NotApprovePage from './NotApprovedPage/NotApprovePage';
import UserHomePage from './UserHomePage/UserHomePage';

const Home = () => {
	const user = getStoredData('user-details');

	return user && user?.isApprovedByAdmin !== 'APPROVED' ? (
		<NotApprovePage result={user?.isApprovedByAdmin} />
	) : (
		<UserHomePage />
	);
};

export default Home;
