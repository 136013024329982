import { forwardRef } from 'react';

import TicketToPrint from './organisms/TicketToPrint/TicketToPrint';

const PrintableProduct = forwardRef(({ items = [] }, ref) => {
	return (
		<div className="container px-1" ref={ref}>
			{items.map((item) => (
				<TicketToPrint key={item?.Barcode} item={item} />
			))}
		</div>
	);
});

export default PrintableProduct;
