import { productLimit } from '../utils/productUtils';
import api from './config/axiosInstance';

const productsAPIs = {
	async getProducts({ page, searchQuery, filterValues }) {
		const query = searchQuery;
		const myParams = new URLSearchParams();

		myParams.set('page', page);
		myParams.set('limit', productLimit);

		if (query) {
			myParams.set('query', query);
		}

		let URI = `/products?${myParams}`;

		if (filterValues?.length > 0) {
			// eslint-disable-next-line array-callback-return
			filterValues?.map((item) => {
				if (item.key) {
					URI += `&filter=${item.key}`;
				}
			});
		}

		try {
			const response = await api.get(URI);
			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async submitBatchProduct(data) {
		try {
			const formData = new FormData();
			formData.append('file', data);

			const response = await api.post('/products', formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async submitSingleProduct(data) {
		try {
			const response = await api.post('/products/add', data);
			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async updateProduct({ data, id }) {
		try {
			const response = await api.put(`/products/${id}`, data);
			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async deleteProduct(id) {
		try {
			const response = await api.delete(`/products/${id}`);
			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async deleteProducts(id = []) {
		try {
			const response = await api.delete(`/products`, {
				data: {
					id,
				},
			});

			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async deleteAllProducts() {
		try {
			const response = await api.delete('/products/all');
			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async exportProducts() {
		try {
			const response = await api.post('/products/export');
			// Create blob link to download
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `Products.csv`);
			// Append to html link element page
			document.body.appendChild(link);
			// Start download
			link.click();
			// Clean up and remove the link
			link.parentNode.removeChild(link);

			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async getSkuList(params = {}) {
		if (!params?.sku) {
			return { error: 'SKU is required' };
		}

		const searchParams = new URLSearchParams();

		if (params?.sku) {
			searchParams.set('sku', params.sku);
		}

		if (params?.page) {
			searchParams.set('page', params.page);
		}

		if (params?.limit) {
			searchParams.set('limit', params.limit);
		}

		try {
			const response = await api.get('/products/sku', {
				params: searchParams,
			});

			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async getBarcodeList(params = {}) {
		if (!params?.barcode) {
			return { error: 'Barcode is required' };
		}

		const searchParams = new URLSearchParams();

		if (params?.barcode) {
			searchParams.set('barcode', params.barcode);
		}

		if (params?.page) {
			searchParams.set('page', params.page);
		}

		if (params?.limit) {
			searchParams.set('limit', params.limit);
		}

		try {
			const response = await api.get('/products/barcode', {
				params: searchParams,
			});

			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},
};

export const {
	getProducts,
	submitBatchProduct,
	submitSingleProduct,
	updateProduct,
	deleteProduct,
	deleteProducts,
	exportProducts,
	getSkuList,
	getBarcodeList,
	deleteAllProducts,
} = productsAPIs;

export default productsAPIs;
