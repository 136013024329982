import { getAccessToken } from '../utils/localStorage';

export const types = {
	SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
	REMOVE_AUTH_TOKEN: 'REMOVE_AUTH_TOKEN',
};

export const initialState = getAccessToken() || '';

// eslint-disable-next-line default-param-last
export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_AUTH_TOKEN:
			// eslint-disable-next-line no-return-assign, no-param-reassign
			return (state = action.payload);

		case types.REMOVE_AUTH_TOKEN:
			// eslint-disable-next-line no-return-assign, no-param-reassign, no-unused-vars
			return (state = '');

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
};

export const setAuthToken = (payload = '') => {
	return { type: types.SET_AUTH_TOKEN, payload };
};

export const removeAuthToken = () => {
	const lang = localStorage.getItem('language');
	const fontSize = localStorage.getItem('fontSize');

	localStorage.clear();

	if (lang) {
		localStorage.setItem('language', lang);
	}

	if (fontSize) {
		localStorage.setItem('fontSize', fontSize);
	}

	return { type: types.REMOVE_AUTH_TOKEN };
};
