const PhoneIcon = ({ size = 16, className = '' }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			className={className}
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.25 0C12.4926 0 13.5 1.00736 13.5 2.25V13.75C13.5 14.9926 12.4926 16 11.25 16H4.75C3.50736 16 2.5 14.9926 2.5 13.75V2.25C2.5 1.00736 3.50736 0 4.75 0H11.25ZM11.25 1.5H4.75C3.94772 1.5 3.28595 2.16176 3.28595 2.96405V13.0359C3.28595 13.8382 3.94772 14.5 4.75 14.5H11.25C12.0523 14.5 12.714 13.8382 12.714 13.0359V2.96405C12.714 2.16176 12.0523 1.5 11.25 1.5ZM8 14.25C7.38579 14.25 6.88596 13.75 6.88596 13.0359C6.88596 12.3217 7.38579 11.8219 8 11.8219C8.61421 11.8219 9.11404 12.3217 9.11404 13.0359C9.11404 13.75 8.61421 14.25 8 14.25Z"
				fill="#23262F"
			/>
		</svg>
	);
};

export default PhoneIcon;
