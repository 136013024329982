import { ErrorMessage, useField, useFormikContext } from 'formik';
import CreatableSelect from 'react-select/creatable';

const SearchableSelectInput = ({
	isMulti = false,
	placeholder = '',
	label = '',
	setFieldState = null,
	options = [],
	...props
}) => {
	const { setFieldValue } = useFormikContext();
	// eslint-disable-next-line no-unused-vars
	const [field, meta] = useField(props);

	const handleChange = (selectedOption) => {
		if (field.name === 'Barcode') {
			const items = selectedOption?.map((item) => item?.value) || [];
			localStorage.setItem('barcode', JSON.stringify(items));
			setFieldValue(field.name, items);
		} else {
			setFieldValue(field.name, selectedOption?.value || '');
		}
	};

	return (
		<div className="mb-3">
			{label && (
				<label htmlFor={field?.name} className="text-start mb-1" style={{ fontSize: '14px', fontWeight: '500' }}>
					{label}
				</label>
			)}

			{isMulti ? (
				<CreatableSelect
					// isLoading={isLoading}
					isClearable
					isMulti={isMulti}
					name={field?.name}
					placeholder={placeholder}
					options={options}
					defaultValue={Array.isArray(field?.value) ? field?.value.map((item) => ({ value: item, label: item })) : []}
					isOptionDisabled={(option) => !String(option?.label).includes('Create')}
					onChange={handleChange}
					onInputChange={(inputValue) => {
						if (inputValue) {
							setFieldState(inputValue);
						}
					}}
					styles={{
						control: (baseStyles) => ({
							...baseStyles,
							background: 'rgb(245, 246, 250)',
							borderColor: 'rgb(245, 246, 250)',
							boxShadow: 'none',
							':hover': {
								borderColor: 'rgb(245, 246, 250)',
							},
						}),
					}}
				/>
			) : (
				<CreatableSelect
					isClearable
					name={field?.name}
					placeholder={placeholder}
					options={options}
					defaultInputValue={field?.value}
					isOptionDisabled={(option) => !String(option?.label).includes('Create')}
					onChange={handleChange}
					onInputChange={(inputValue) => {
						if (inputValue) {
							setFieldState(inputValue);
						}
					}}
					styles={{
						control: (baseStyles) => ({
							...baseStyles,
							background: 'rgb(245, 246, 250)',
							borderColor: 'rgb(245, 246, 250)',
							boxShadow: 'none',
							':hover': {
								borderColor: 'rgb(245, 246, 250)',
							},
						}),
					}}
				/>
			)}

			<ErrorMessage
				name={field?.name}
				component="div"
				className="error text-start mt-2"
				style={{
					color: '#DC2626',
					fontSize: '14px',
					fontWeight: '500',
				}}
			/>
		</div>
	);
};

export default SearchableSelectInput;
