import Pagination from 'react-bootstrap/Pagination';

const PaginationContainer = ({ total = 0, current = 1, onChangePage, take = 100 }) => {
	const items = [];
	const totalPage = Math.ceil(total / take);

	// eslint-disable-next-line no-plusplus
	for (let page = 1; page <= totalPage; page++) {
		if (totalPage >= 3 && page > 1 && page < Math.round(totalPage / 2) && page === current) {
			items.push(
				<Pagination.Item
					key={page}
					data-page={page}
					active={page === current}
					onClick={() => onChangePage(page)}
					style={{
						cursor: 'pointer',
					}}
				>
					{page}
				</Pagination.Item>
			);
		}

		if (page === Math.round(totalPage / 2)) {
			items.push(
				<Pagination.Item
					key={page}
					data-page={page}
					active={page === current}
					onClick={() => onChangePage(page)}
					style={{
						cursor: 'pointer',
					}}
				>
					{page}
				</Pagination.Item>
			);
		}

		if (totalPage >= 3 && page > Math.round(totalPage / 2) && page < totalPage && page === current) {
			items.push(
				<Pagination.Item
					key={page}
					data-page={page}
					active={page === current}
					onClick={() => onChangePage(page)}
					style={{
						cursor: 'pointer',
					}}
				>
					{page}
				</Pagination.Item>
			);
		}
	}

	return (
		<>
			{/* Small device */}
			<Pagination size="lg" className="show-sm">
				<Pagination.First onClick={() => onChangePage(1)} />
				<Pagination.Prev
					className={current > 1 ? '' : 'disabled'}
					key="prev"
					onClick={() => onChangePage(current - 1)}
				/>
				<Pagination.Item active={!!current} onClick={() => onChangePage(current)}>
					{current}
				</Pagination.Item>
				<Pagination.Next
					className={current === totalPage ? 'disabled' : ''}
					key="next"
					onClick={() => onChangePage(current + 1)}
				/>
				<Pagination.Last onClick={() => onChangePage(totalPage)} />
			</Pagination>

			{/* Large device */}
			<Pagination size="lg" className="hide-sm">
				<Pagination.Prev
					className={current > 1 ? '' : 'disabled'}
					key="prev"
					onClick={() => onChangePage(current - 1)}
				/>
				{totalPage >= 3 && (
					<Pagination.Item active={current === 1} onClick={() => onChangePage(1)}>
						{1}
					</Pagination.Item>
				)}

				{totalPage > 3 && <Pagination.Ellipsis />}

				{items}

				{totalPage > 3 && <Pagination.Ellipsis />}

				{totalPage >= 3 && (
					<Pagination.Item active={current === totalPage} onClick={() => onChangePage(totalPage)}>
						{totalPage}
					</Pagination.Item>
				)}

				<Pagination.Next
					className={current === totalPage ? 'disabled' : ''}
					key="next"
					onClick={() => onChangePage(current + 1)}
				/>
			</Pagination>
		</>
	);
};

export default PaginationContainer;
