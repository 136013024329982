import { createContext, useReducer } from 'react';

import { initialState, reducer } from '../reducers/userRoleReducer';

export const UserRoleContext = createContext();

export const UserRoleContextProvider = ({ children = null }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	// eslint-disable-next-line react/jsx-no-constructed-context-values
	const value = { state, dispatch };

	return <UserRoleContext.Provider value={value}>{children}</UserRoleContext.Provider>;
};
