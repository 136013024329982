import { Field, useField } from 'formik';
import { boolean } from 'yup';

import EyeIcon from '../../assets/svgs/EyeIcon';
import HideIcon from '../../assets/svgs/HideIcon';
import isEmpty from '../../utils/isEmpty';

const InputField = ({
	icon = {},
	showicon = false,
	placeholder = '',
	label = '',
	name = '',
	type = '',
	setShowPassword = boolean,
	showPassword = [],
}) => {
	// eslint-disable-next-line no-unused-vars
	const [field, meta, helpers] = useField({ name });

	return (
		<div className="mb-4">
			{label && (
				<label htmlFor={name} className="text-start mb-1" style={{ fontSize: '14px', fontWeight: '500' }}>
					{label}
				</label>
			)}

			<div className="d-flex border rounded-2 py-2 px-2 w-100 gap-1" style={{ background: 'rgb(245, 246, 250)' }}>
				{!isEmpty(icon) && <div className="d-flex justify-content-center align-items-center">{icon}</div>}

				<Field
					className="w-100"
					name={name}
					id={name}
					placeholder={`${placeholder}`}
					type={type}
					style={{
						border: 'none',
						outline: 'none',
						fontSize: '14px',
						fontWeight: '500',
						background: 'rgb(245, 246, 250)',
						paddingLeft: '10px',
					}}
				/>

				{showicon &&
					(showPassword ? (
						<button
							style={{ border: 'none', backgroundColor: 'transparent' }}
							onClick={() => setShowPassword(!showPassword)}
							type="button"
						>
							<EyeIcon className="w-4 h-5" />
						</button>
					) : (
						<button
							style={{ border: 'none', backgroundColor: 'transparent' }}
							onClick={() => setShowPassword(!showPassword)}
							type="button"
						>
							<HideIcon className="w-4 h-5" />
						</button>
					))}
			</div>

			{meta.touched && meta.error ? (
				<div
					className="error text-start mt-2"
					style={{
						color: '#DC2626',
						fontSize: '14px',
						fontWeight: '500',
						marginBottom: '-15px',
					}}
				>
					{meta.error}
				</div>
			) : null}
		</div>
	);
};

export default InputField;
