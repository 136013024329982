import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext';
import { UserRoleContextProvider } from './contexts/UserRoleContext';
import BaseLayout from './layout/BaseLayout';

const queryClient = new QueryClient();

const App = () => {
	return (
		<QueryClientProvider client={queryClient}>
			<BrowserRouter>
				<AuthProvider>
					<UserRoleContextProvider>
						<Toaster
							position="bottom-center"
							reverseOrder={false}
							gutter={8}
							containerStyle={{}}
							toastOptions={{
								className: '',
								duration: 4000,
								style: {
									borderRadius: '10px',
									background: '#333',
									color: '#fff',
								},
							}}
						/>
						<BaseLayout />
					</UserRoleContextProvider>
				</AuthProvider>
			</BrowserRouter>
		</QueryClientProvider>
	);
};

export default App;
