export const productLimit = '100';
export const productGroupEnum = ['FOODS', 'DRINKS'];
export const barcodeLength = 12;
export const productFilterDropdownData = [
	{
		cat: 'Name',
		key: 'Name',
	},
	{
		cat: 'Weight',
		key: 'Weight',
	},
	{
		cat: 'Brand',
		key: 'Brand',
	},
	{
		cat: 'ProductGroup',
		key: 'ProductGroup',
	},
	{
		cat: 'SKU',
		key: 'SKU',
	},
	{
		cat: 'Barcode',
		key: 'Barcode',
	},
	{
		cat: 'MeasurementUnit',
		key: 'MeasurementUnit',
	},
	{
		cat: 'Cost',
		key: 'Cost',
	},
	{
		cat: 'Markup',
		key: 'Markup',
	},
	{
		cat: 'Price',
		key: 'Price',
	},
	{
		cat: 'UnitPrice',
		key: 'UnitPrice',
	},
	{
		cat: 'Tax',
		key: 'Tax',
	},
	{
		cat: 'IsTaxInclusivePrice',
		key: 'IsTaxInclusivePrice',
	},
	{
		cat: 'IsPriceChangeAllowed',
		key: 'IsPriceChangeAllowed',
	},
	{
		cat: 'IsUsingDefaultQuantity',
		key: 'IsUsingDefaultQuantity',
	},
	{
		cat: 'IsService',
		key: 'IsService',
	},
	{
		cat: 'IsEnabled',
		key: 'IsEnabled',
	},
	{
		cat: 'Description',
		key: 'Description',
	},
	{
		cat: 'Supplier',
		key: 'Supplier',
	},
	{
		cat: 'ReorderPoint',
		key: 'ReorderPoint',
	},
	{
		cat: 'PreferredQuantity',
		key: 'PreferredQuantity',
	},
	{
		cat: 'LowStockWarning',
		key: 'LowStockWarning',
	},
	{
		cat: 'WarningQuantity',
		key: 'WarningQuantity',
	},
	// Added new custom label which can be customized by the user
	{
		cat: 'CustomLabel',
		key: 'CustomLabel',
	},
	{
		cat: 'Promo',
		key: 'Promo',
	},
];
