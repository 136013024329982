import _ from 'lodash';

import { DEFAULT_BARCODE_HEIGHT } from '../constants/default';
import isEmpty from './isEmpty';

export function saveToLS(key, value) {
	if (global.localStorage) {
		localStorage.setItem(
			'rgl-8',
			JSON.stringify({
				[key]: value,
			})
		);
	}
}

export function getFromLS(key) {
	let ls = {};
	if (global.localStorage) {
		try {
			ls = JSON.parse(localStorage.getItem('rgl-8')) || {};
		} catch (e) {
			/* Ignore */
		}
	}
	return ls[key];
}

export const getPrintableProductValue = (object, key) => {
	if (!isEmpty(object)) {
		return object[key];
	}
	return 'Not found';
};

export const staticTicketItems = [
	{
		title: 'Name',
		pid: 'name',
	},
	{
		title: 'Barcode',
		pid: 'barcode',
	},
];

/** LEGACY */
// export const defaultPrintProperties = {
// 	// eslint-disable-next-line func-names
// 	lg: staticTicketItems.map(function (item, i) {
// 		const y = Math.ceil(Math.random() * 4) + 1;
// 		return {
// 			title: item.title,
// 			pid: item.pid,
// 			x: (_.random(0, 5) * 2) % 12,
// 			y: Math.floor(i / 6) * y,
// 			w: item.pid === 'barcode' ? 6 : 2,
// 			h: item.pid === 'barcode' ? 6 : 3,
// 			minW: item.pid === 'barcode' ? 6 : 1,
// 			minH: item.pid === 'barcode' ? 6 : 1,
// 			i: i.toString(),
// 			static: false,
// 		};
// 	}),
// };

export const defaultPrintProperties = {
	// eslint-disable-next-line func-names
	lg: staticTicketItems.map(function (item, i) {
		const y = Math.ceil(Math.random() * 4) + 1;
		return {
			title: item.title,
			pid: item.pid,
			x: (_.random(0, 5) * 2) % 12,
			y: Math.floor(i / 6) * y,
			w: 2,
			h: item.pid === 'barcode' ? 3 : 2,
			minW: 1,
			minH: 1,
			i: i.toString(),
			static: false,
			height: item.pid === 'barcode' ? DEFAULT_BARCODE_HEIGHT : 0,
		};
	}),
};

/**
 * The `convertToRange` function takes a value and maps it from a source range to a destination range.
 * @param value - The value parameter represents the value that you want to convert to a new range.
 * @param srcRange - The `srcRange` parameter is an array that represents the source range of values.
 * It contains two elements: the minimum value of the range at index 0, and the maximum value of the
 * range at index 1.
 * @param dstRange - The `dstRange` parameter represents the desired range that you want to convert the
 * `value` to. It is an array with two elements: the minimum value of the desired range (`dstRange[0]`)
 * and the maximum value of the desired range (`dstRange[1]`).
 * @returns the converted value within the destination range.
 */
export function convertToRange(value, srcRange, dstRange) {
	// value is outside source range return
	if (value < srcRange[0] || value > srcRange[1]) {
		return NaN;
	}

	const srcMax = srcRange[1] - srcRange[0];
	const dstMax = dstRange[1] - dstRange[0];
	const adjValue = value - srcRange[0];

	return (adjValue * dstMax) / srcMax + dstRange[0];
}

export function widthConverter(value) {
	return Number(convertToRange(value, [0, 100], [1, 21]));
}
