// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

import Spinner from '../atoms/Spinner';

const LoadingButtonWithTitle = ({ title = '', loading = false }) => {
	return (
		<div className="d-flex justify-content-center align-item-middle gap-2">
			<span className="fs-5">{loading ? 'Loading...' : title}</span>
			{loading && <Spinner />}
		</div>
	);
};

LoadingButtonWithTitle.propTypes = {
	title: PropTypes.string.isRequired,
};

export default LoadingButtonWithTitle;
