import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '../assets/images/qpos-logo.png';
import useAuth from '../hooks/useAuth';
import useCurrentUserRole from '../hooks/useCurrentUserRole';
import { removeAuthToken } from '../reducers/authReducer';
import { removeCurrentUserMode } from '../reducers/userRoleReducer';
import { getStoredData } from '../utils/localStorage';

const Navigation = () => {
	const { state: token, dispatch } = useAuth();
	const { state: currentUserRole, dispatch: dispatchCurrentUserRole } = useCurrentUserRole();
	const navigateTo = useNavigate();

	const handleSignOut = () => {
		dispatch(removeAuthToken());
		dispatchCurrentUserRole(removeCurrentUserMode());
		navigateTo('/signin');
		window.location.reload();
		toast.success('Successfully signed out');
	};

	return (
		<nav className="navbar bg-body-tertiary" style={{ height: '80px' }}>
			<div className="container">
				<div className="container-fluid d-flex justify-content-between">
					<Link className="navbar-brand" to={`${currentUserRole === 'ADMIN' ? '/users' : '/'}`} reloadDocument>
						<img
							style={{
								height: '29px',
							}}
							src={Logo}
							alt="QPOC Logo"
						/>
					</Link>

					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '50px',
						}}
					>
						{token && currentUserRole !== 'ADMIN' && (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '20px',
								}}
							>
								<a href="/ticket-template" className="nav-url">
									<span>Template</span>
								</a>
							</div>
						)}

						{getStoredData('token') && (
							<button className="btn btn-outline-secondary" type="button" onClick={handleSignOut}>
								Logout
							</button>
						)}
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navigation;
