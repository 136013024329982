import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Error from '../atoms/Error';

const DeleteModal = ({
	children,
	showDeleteModal,
	handleCloseDeleteModal,
	handleProductDelete,
	isError,
	error,
	isLoading,
	hideFooter = false,
}) => {
	return (
		<Modal
			show={showDeleteModal}
			onHide={handleCloseDeleteModal}
			keyboard={false}
			centered
			className="delete-modal-custom"
		>
			<Modal.Body
				style={{
					maxHeight: '75vh',
					overflow: 'hidden',
					overflowY: 'auto',
					padding: '30px',
				}}
			>
				{children}
			</Modal.Body>

			{!hideFooter && (
				<Modal.Footer>
					{isError && (
						<div className="w-100">
							<Error error={error} />
						</div>
					)}

					<Button disabled={isLoading} variant="secondary" onClick={handleCloseDeleteModal}>
						No
					</Button>
					<Button disabled={isLoading} onClick={handleProductDelete} variant="danger">
						Yes
					</Button>
				</Modal.Footer>
			)}
		</Modal>
	);
};

export default DeleteModal;
