import { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import { AiFillDelete } from 'react-icons/ai';
import { BiSolidEditAlt } from 'react-icons/bi';
import { BsChevronDown } from 'react-icons/bs';
import { IoCopyOutline } from 'react-icons/io5';
import { TfiPrinter } from 'react-icons/tfi';
import { useReactToPrint } from 'react-to-print';

import { formatStringToFloat } from '../../utils/formatStringToFloat';
import PrintableProduct from '../PrintableProduct';

const ProductCard = ({
	product = {},
	handleSelectProductItem = null,
	handleShowModal = null,
	handleShowDeleteModal = null,
}) => {
	const [isCardExpanded, setIsCardExpanded] = useState(false);
	const printItemRef = useRef();
	const [selectedBarcode, setSelectedBarcode] = useState(product?.Barcode[0]);
	const [selectedProductForPrint, setSelectedProductForPrint] = useState(product);
	// eslint-disable-next-line no-unused-vars
	const [copyOptions, setCopyOptions] = useState({
		copied: false,
		value: '',
	});

	const printSelectedItem = useReactToPrint({
		content: () => printItemRef.current,
	});

	const handleEdit = () => {
		handleShowModal(product);
	};

	/**
	 * The function `handleSelectProductItemWithCustomizedBarcode` updates a product with a selected
	 * barcode and then calls two other functions with the updated product.
	 */
	const handleSelectProductItemWithCustomizedBarcode = (product) => (event) => {
		const productWithSelectedBarcode = {
			...product,
			Barcode: [selectedBarcode],
		};

		handleSelectProductItem(productWithSelectedBarcode)(event);
		setSelectedProductForPrint(productWithSelectedBarcode);
	};

	/**
	 * The function `handleSelectBarcode` updates the selected barcode for a product and triggers the
	 * selection of the product item with the updated barcode.
	 */
	const handleSelectBarcode = (product, barcode) => (event) => {
		const barcodeStr = barcode.toString();
		setSelectedBarcode(barcodeStr);

		const productWithSelectedBarcode = {
			...product,
			Barcode: [barcodeStr],
		};
		handleSelectProductItem(productWithSelectedBarcode)(event);
		setSelectedProductForPrint(productWithSelectedBarcode);
	};

	/**
	 * The function `handleChangeBarcodeCopyInput` updates the `copyOptions` state with a new value and
	 * sets `copied` to false based on the input event.
	 */
	const handleChangeBarcodeCopyInput = (event) => {
		const { value } = event.target;
		setCopyOptions({ value, copied: false });
	};

	/**
	 * The function `handleCopyBarcode` updates the `copied` property in the `copyOptions` state to `true`.
	 */
	const handleCopyBarcode = () => {
		setCopyOptions((prevOptions) => ({ ...prevOptions, copied: true }));
		toast.success('Barcode copied to clipboard');
	};

	return (
		<div className="col-12 col-lg-6">
			<div
				className="position-relative rounded custom-card"
				style={{
					padding: '3.2rem 2rem',
					overflow: 'hidden',
					border: '1px solid #ddd',
				}}
			>
				{/* top buttons container */}
				<div
					style={{
						position: 'absolute',
						top: '12px',
						right: '15px',
						width: 'fit-content',
						background: 'transparent',
						border: '0',
						marginLeft: 'auto',
						fontSize: '20px',
						transition: 'all 0.2s',
						display: 'flex',
						alignItems: 'center',
						gap: '12px',
					}}
				>
					<button type="button" onClick={printSelectedItem} className="text-center text-secondary" title="Print">
						<TfiPrinter />
					</button>

					<button type="button" onClick={handleEdit} className="text-center text-primary" title="Edit">
						<BiSolidEditAlt />
					</button>

					<button type="button" className="text-center text-danger" title="Delete">
						<AiFillDelete
							onClick={() => handleShowDeleteModal(product?.id)}
							style={{
								fontSize: '20px',
								cursor: 'pointer',
								textAlign: 'center',
							}}
						/>
					</button>

					{/* Select checkbox */}
					<div
						style={{
							transform: 'translateY(2px)',
							marginLeft: '5px',
						}}
					>
						<input
							title="Select"
							className="form-check-input"
							type="checkbox"
							value={product?.Barcode}
							name={product?.Barcode}
							id={product?.Barcode}
							onChange={handleSelectProductItemWithCustomizedBarcode(product)}
							checked={product?.isChecked}
							style={{
								cursor: 'pointer',
							}}
						/>
					</div>
				</div>

				{/* Card body */}
				<div
					className={`${isCardExpanded ? '' : 'line-clamp-4'} overflow-hidden`}
					style={{
						maxHeight: isCardExpanded ? '100vh' : '164px',
						transition: 'all 0.2s',
					}}
				>
					{/* <div className="fw-bold fs-5 md-mr-100">{product?.Name}</div> */}
					{/* <div>Price: €{product?.Price}</div> */}
					{/* <div>Cost: €{product?.Cost}</div> */}
					{/* <div>Description: {product?.Description}</div> */}

					{/* <div>ProductGroup: {product?.ProductGroup}</div> */}
					{/* <div>SKU: {product?.SKU}</div> */}
					{/* <div>Barcode: {product?.Barcode}</div> */}
					{/* <div>Markup: {product?.Markup}</div> */}
					{/* <div>Tax: {product?.Tax}</div> */}
					{/* <div>IsTaxInclusivePrice: {product?.IsTaxInclusivePrice}</div> */}
					{/* <div>IsPriceChangeAllowed: {product?.IsPriceChangeAllowed}</div> */}
					{/* <div>IsUsingDefaultQuantity: {product?.IsUsingDefaultQuantity}</div> */}
					{/* <div>IsEnabled: {product?.IsEnabled}</div> */}
					{/* <div>Supplier: {product?.Supplier}</div> */}
					{/* <div>ReorderPoint: {product?.ReorderPoint}</div> */}
					{/* <div>PreferredQuantity: {product?.PreferredQuantity}</div> */}
					{/* <div>: {product?.LowStockWarning}</div> */}

					<div className="table-responsive">
						<table className="table table-bordered table-striped table-hover">
							{/* <thead>
                <tr>
                  {headers &&
                    headers?.map((header) => (
                      <th scope="col" key={header}>
                        {header}
                      </th>
                    ))}
                </tr>
              </thead> */}

							<tbody>
								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>Name: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.Name}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>Price: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>€{formatStringToFloat(product?.Price)}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>Barcode: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div className="">
											{product?.Barcode?.length > 0 && (
												<div className="d-flex gap-3">
													{product?.Barcode?.map((barcode) => (
														<div className="position-relative">
															<div className="">
																<input
																	key={`barcode-${barcode}`}
																	type="radio"
																	className={`btn-check ${selectedBarcode === barcode?.toString() && 'active'}`}
																	name={product?.Name}
																	id={`barcode-${barcode}`}
																	value={selectedBarcode}
																	checked={selectedBarcode === barcode?.toString()}
																	onChange={handleSelectBarcode(product, barcode)}
																	autoComplete="off"
																/>

																<label className="btn btn-outline-dark" htmlFor={`barcode-${barcode}`}>
																	{barcode}
																</label>
															</div>

															<div
																className="position-absolute"
																style={{
																	top: '-13px',
																	right: '-16px',
																}}
															>
																<input
																	className="form-control form-control-sm d-none"
																	value={barcode}
																	onChange={handleChangeBarcodeCopyInput}
																/>

																<CopyToClipboard text={barcode} onCopy={handleCopyBarcode}>
																	<button type="button" className="btn btn-sm btn-outlined-light p-0 m-0">
																		<IoCopyOutline size={22} />
																	</button>
																</CopyToClipboard>
															</div>
														</div>
													))}
												</div>
											)}
										</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>Cost: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>€{formatStringToFloat(product?.Cost)}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>Weight: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.Weight}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>Brand: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.Brand}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>Description: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div className={!isCardExpanded ? 'truncate' : ''}>{product?.Description}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>ProductGroup: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.ProductGroup}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>SKU: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.SKU}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>Markup: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>€{formatStringToFloat(product?.Markup)}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>Tax: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>€{formatStringToFloat(product?.Tax)}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>IsTaxInclusivePrice: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.IsTaxInclusivePrice}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>IsUsingDefaultQuantity: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.IsUsingDefaultQuantity}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>IsEnabled: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.IsEnabled}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>Supplier: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.Supplier}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>ReorderPoint: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.ReorderPoint}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>PreferredQuantity: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.PreferredQuantity}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>LowStockWarning: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.LowStockWarning}</div>
									</td>
								</tr>

								<tr className="text-center">
									<td style={{ minWidth: '100px' }} className="text-start">
										<div>WarningQuantity: </div>
									</td>

									<td style={{ minWidth: '100px' }} className="text-start">
										<div>{product?.WarningQuantity}</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					{/* arrow icon */}
					<Button
						onClick={() => setIsCardExpanded((old) => !old)}
						variant="light"
						style={{
							position: 'absolute',
							bottom: '15px',
							right: '15px',
							width: 'fit-content',
							// background: "white",
							border: '1px solid  #ddd',
							borderRadius: '100%',
							padding: '5px 10px',
							// border: "0",
							marginLeft: 'auto',
							fontSize: '20px',
							rotate: isCardExpanded ? '180deg' : '0deg',
							transition: 'all 0.2s',
						}}
					>
						<BsChevronDown />
					</Button>
				</div>
			</div>

			<div className="hide-container">
				<PrintableProduct ref={printItemRef} items={[selectedProductForPrint]} />
			</div>
		</div>
	);
};

export default ProductCard;
