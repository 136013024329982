import { useEffect, useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import toast from 'react-hot-toast';
import { AiFillDelete } from 'react-icons/ai';
import { CgPlayListRemove } from 'react-icons/cg';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

// eslint-disable-next-line prettier/prettier
import {
	deleteAllProducts,
	deleteProduct,
	deleteProducts,
	exportProducts,
	getProducts,
	submitBatchProduct,
	submitSingleProduct,
	updateProduct,
} from '../../apis/productsAPIs';
import { getTickets } from '../../apis/ticketsAPIs';
import ChevronDownIcon from '../../assets/svgs/ChevronDownIcon';
import Error from '../../components/atoms/Error';
import MultiSelectDropDown from '../../components/molecules/MultiSelectDropDown';
import PaginationContainer from '../../components/molecules/PaginationContainer';
import ProductCard from '../../components/molecules/ProductCard';
import DeleteModal from '../../components/organisms/DeleteModal';
import ProductsModal from '../../components/organisms/ProductsModal';
import PrintableProduct from '../../components/PrintableProduct';
import { FILE_UPLOAD_DELAY } from '../../constants/default';
import { defaultPrintProperties, getFromLS } from '../../utils/helpers';
import { initialProducts } from '../../utils/initialProducts';
import isEmpty from '../../utils/isEmpty';
import { barcodeLength, productFilterDropdownData, productLimit } from '../../utils/productUtils';

const UserHomePage = () => {
	const [data, setData] = useState([]);
	const [file, setFile] = useState(null);
	const [selectedItems, setSelectedItems] = useState([]);

	// eslint-disable-next-line
	const [headers, setHeaders] = useState([]);
	const [modalData, setModalData] = useState({});
	const [deleteDataId, setDeleteDataId] = useState('');
	const [deleteProductIds, setDeleteProductIds] = useState([]);
	const [dataChangeType, setDataChangeType] = useState('');
	const [page, setPage] = useState(1);
	const [searchQuery, setSearchQuery] = useState('');
	const [filterValues, setFilterValues] = useState([]);
	const [barcodeValue, setBarcodeValue] = useState('');
	const [barcodeScanComplete, setBarcodeScanComplete] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [layouts, setLayouts] = useState({});

	const [showModal, setShowModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showBatchUploadModal, setShowBatchUploadModal] = useState(false);
	const [batchUploadComplete, setBatchUploadComplete] = useState(false);
	const [showDeleteProductsModal, setShowDeleteProductsModal] = useState(false);
	const [showDeleteAllProductsModal, setShowDeleteAllProductsModal] = useState(false);

	const navigate = useNavigate();
	const submitBtnRef = useRef();
	const printProductRef = useRef();
	const selectAllCheckboxRef = useRef();

	// GET - products mutation
	const {
		data: productsData,
		isLoading: isLoadingProduct,
		refetch: refetchProducts,
		isFetched: isFetchedProducts,
	} = useQuery(['products'], () => getProducts({ page, searchQuery, filterValues }), {
		refetchOnWindowFocus: false,
		enabled: false,
	});

	// GET - tickets mutation
	const { data: ticketsData, isLoading: isLoadingTicketData } = useQuery(['tickets'], () => getTickets());

	// POST - Single product
	const {
		isError: isErrorSubmitProduct,
		mutateAsync,
		error: errorSubmitProduct,
		isLoading: isLoadingSubmitProduct,
	} = useMutation((data) => submitSingleProduct(data));

	// POST - EXPORT product
	const { mutateAsync: mutateAsyncExportProducts, isLoading: isLoadingExportProducts } = useMutation(() =>
		exportProducts()
	);

	const {
		isError: isErrorSubmitBatchProduct,
		mutateAsync: mutateAsyncBatchProduct,
		error: errorSubmitBatchProduct,
	} = useMutation((data) => submitBatchProduct(data));

	// PUT - Update product
	const {
		isError: isErrorUpdateProduct,
		mutateAsync: mutateAsyncUpdateProduct,
		error: errorUpdateProduct,
		isLoading: isLoadingUpdateProduct,
	} = useMutation((data) => updateProduct(data));

	// DELETE - Delete Single product
	const {
		isError: isErrorDeleteProduct,
		mutateAsync: mutateAsyncDeleteProduct,
		error: errorDeleteProduct,
		isLoading: isLoadingDeleteProduct,
	} = useMutation((id) => deleteProduct(id));

	// DELETE - Delete multiple products
	const {
		isError: isErrorDeleteProducts,
		// eslint-disable-next-line no-unused-vars
		mutateAsync: mutateAsyncDeleteProducts,
		error: errorDeleteProducts,
		isLoading: isLoadingDeleteProducts,
	} = useMutation((id) => deleteProducts(id));

	// DELETE - Delete all products
	const {
		// eslint-disable-next-line no-unused-vars
		isError: isErrorDeleteAllProducts,
		// eslint-disable-next-line no-unused-vars
		mutateAsync: mutateAsyncDeleteAllProducts,
		// eslint-disable-next-line no-unused-vars
		error: errorDeleteAllProducts,
		// eslint-disable-next-line no-unused-vars
		isLoading: isLoadingDeleteAllProducts,
	} = useMutation(() => deleteAllProducts());

	const handleCloseModal = () => {
		setShowModal(false);
		setTimeout(() => {
			setModalData({});
		}, 500);
	};

	const handleShowModal = (product) => {
		if (typeof product === 'object') {
			setModalData(product);
		}
		setShowModal(true);
	};

	// delete modal handlers
	const handleShowDeleteModal = (id) => {
		setShowDeleteModal(true);
		setDeleteDataId(id);
	};

	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false);
	};

	// handle delete api
	const handleProductDelete = async () => {
		const response = await mutateAsyncDeleteProduct(deleteDataId);

		if ([200, 201].includes(response.status)) {
			toast.success(response.message);
			handleCloseDeleteModal();
			refetchProducts();
		}
	};

	const editProductHandler = (data) => {
		setDataChangeType('Edit');
		handleShowModal(data);
	};

	const openAddProductHandler = () => {
		setModalData(initialProducts);
		setDataChangeType('Add');
		handleShowModal();
	};

	const printItemsRef = useRef();
	const printSelectedItems = useReactToPrint({
		content: () => printItemsRef.current,
	});

	// const storedDataFromLocalStorage = localStorage.getItem("product-data");
	// const lastModifiedDate = localStorage.getItem("last-modified-date");

	const handleChange = async (inputFile) => {
		setShowBatchUploadModal(true);

		if (inputFile) {
			setFile(inputFile);

			const response = await mutateAsyncBatchProduct(inputFile);
			const approximateTime = response?.data?.approximateTime;

			// eslint-disable-next-line no-promise-executor-return
			await new Promise((r) => setTimeout(r, approximateTime + FILE_UPLOAD_DELAY));

			// if success
			if (!isErrorSubmitBatchProduct) {
				setBatchUploadComplete(true);
				// eslint-disable-next-line no-promise-executor-return
				await new Promise((r) => setTimeout(r, FILE_UPLOAD_DELAY)); // wait before closing

				setShowBatchUploadModal(false);
				refetchProducts(); // refetch the products
			}
		}
	};

	/**
	 * The function `handleSelectProductItem` updates the selected items list and data based on the
	 * checkbox state of a product item.
	 */
	const handleSelectProductItem = (product) => (event) => {
		const updatedProductItem = { ...product, isChecked: event.target.checked };

		const updatedData = data?.map((item) =>
			item?.Barcode[0] === updatedProductItem?.Barcode[0] ? updatedProductItem : item
		);

		if (event.target.checked && updatedProductItem?.isChecked) {
			setSelectedItems((prevItems) => [...prevItems, updatedProductItem]);
			setData(updatedData);
		} else {
			setSelectedItems((currentItems) =>
				currentItems?.filter((item) => item?.Barcode[0] !== updatedProductItem?.Barcode[0])
			);
			setData(updatedData);
		}
	};

	const handleSelectAllItemsAtOnce = (event) => {
		const updatedCheckOrUncheckAllItems = data.map((item) => ({
			...item,
			isChecked: event.target.checked,
		}));

		if (event.target.checked && data?.length) {
			setData(updatedCheckOrUncheckAllItems);
			setSelectedItems(updatedCheckOrUncheckAllItems);
		} else {
			setData(updatedCheckOrUncheckAllItems);
			setSelectedItems([]);
		}
	};

	// useLayoutEffect(() => {
	//   if (storedDataFromLocalStorage) {
	//     setData(JSON.parse(storedDataFromLocalStorage));
	//   }
	// }, [storedDataFromLocalStorage]);

	const submitFormHandler = async (values) => {
		const barcodes = JSON.parse(localStorage.getItem('barcode')) || [];

		const data = {
			...values,
			Barcode: barcodes?.length ? barcodes : values?.Barcode,
		};

		delete data.createdAt;
		delete data.updatedAt;
		delete data.id;

		if (!data?.Cost) {
			data.Cost = 0;
		}

		if (!data?.Markup) {
			data.Markup = 0;
		}

		if (!data?.Tax) {
			data.Tax = 0;
		}

		data.ProductGroup = values?.ProductGroup?.toUpperCase();

		let response;
		if (dataChangeType === 'Edit') {
			response = await mutateAsyncUpdateProduct({ id: modalData?.id, data });
		} else {
			response = await mutateAsync(data);
		}

		if ([200, 201].includes(response.status)) {
			toast.success(response.message);
			handleCloseModal();
			refetchProducts();
		}
	};

	const handleFilterSelect = (values) => {
		setFilterValues(values);
	};

	const handleSearchSubmit = (event) => {
		event.preventDefault();
		if (searchQuery?.length || filterValues?.length) {
			refetchProducts();
		}
		if (barcodeValue?.length >= barcodeLength && barcodeValue === searchQuery) {
			setBarcodeScanComplete(true);
		}
	};

	const handleDownload = (event) => {
		event.preventDefault();
		mutateAsyncExportProducts();
	};

	const handleShowDeleteProductsModal = async () => {
		const selectedItemsIds = selectedItems.map((item) => item?.id);
		setDeleteProductIds(selectedItemsIds);
		setShowDeleteProductsModal(true);
	};

	const handleCloseDeleteProductsModal = () => {
		setDeleteProductIds([]);
		setShowDeleteProductsModal(false);
	};

	const handleDeleteProducts = async () => {
		const response = await mutateAsyncDeleteProducts(deleteProductIds);

		if ([200, 201].includes(response.status)) {
			toast.success(response.message);
			handleCloseDeleteProductsModal();
			refetchProducts();
		}
	};

	// Handle Delete All Items
	const handleDeleteAllItems = async () => {
		setShowDeleteAllProductsModal(true);
	};

	const handleCloseDeleteAllProductsModal = () => {
		setShowDeleteAllProductsModal(false);
	};

	const handleDeleteAllProducts = async () => {
		const response = await mutateAsyncDeleteAllProducts();

		if ([200, 201].includes(response.status)) {
			toast.success(response.message);
			handleCloseDeleteAllProductsModal();
			refetchProducts();
			window.location.reload();
		}
	};

	// Detect Barcode
	useEffect(() => {
		let myString = '';
		// eslint-disable-next-line func-names
		document.addEventListener('keydown', function (e) {
			const textInput = e.key || String.fromCharCode(e.keyCode);
			const targetName = e.target.localName;

			if (textInput && textInput.length === 1 && targetName !== 'input') {
				myString += textInput;

				if (myString.length >= barcodeLength) {
					setBarcodeValue(myString);
					myString = '';
				}
			}
		});
		// eslint-disable-next-line
	}, []);

	// insert barcode in search field
	useEffect(() => {
		if (barcodeValue?.length >= barcodeLength) {
			setSearchQuery(barcodeValue);
		}
	}, [barcodeValue]);

	// submit form upon scanning
	useEffect(() => {
		if (barcodeValue?.length >= barcodeLength && barcodeValue === searchQuery) {
			setSelectedItems([]);
			setTimeout(() => {
				// eslint-disable-next-line no-unused-expressions
				submitBtnRef.current && submitBtnRef.current.click();
			}, 0);
		}
	}, [barcodeValue, searchQuery]);

	// add scanned data to selected array
	useEffect(() => {
		if (barcodeScanComplete && data?.length === 1) {
			setSelectedItems(data);
		}
	}, [barcodeScanComplete, data]);

	useEffect(() => {
		if (barcodeScanComplete && selectedItems?.length === 1) {
			// setTimeout(() => {
			//   if (selectAllCheckboxRef.current)
			//     selectAllCheckboxRef.current.checked = true;
			// }, 500);

			setTimeout(() => {
				if (printProductRef.current) printProductRef.current.click();
				setBarcodeScanComplete(false);
			}, 100);
		}
	}, [barcodeScanComplete, selectedItems]);

	// Set default ticket and layout if not set
	useEffect(() => {
		const ticketWidth = localStorage.getItem('ticket_width');
		if (!ticketWidth) {
			localStorage.setItem('ticket_width', 50);
		}
	}, []);

	useEffect(() => {
		const defaultLayouts = getFromLS('rgl-8');

		if (isEmpty(ticketsData?.data?.ticket) && isEmpty(defaultLayouts)) {
			localStorage.setItem('rgl-8', JSON.stringify({ layouts: defaultPrintProperties }));
			setLayouts({ layouts: defaultPrintProperties });
		}

		if (!isEmpty(ticketsData?.data?.ticket)) {
			const layout = ticketsData?.data?.ticket?.ticketLayout;
			setLayouts(layout);
			localStorage.setItem('rgl-8', layout);
		}
	}, [ticketsData]);

	useEffect(() => {
		if (data?.length > 0) {
			setHeaders(Object.keys(data[0]));
		}
	}, [data]);

	useEffect(() => {
		if (file) {
			localStorage.setItem('last-modified-date', JSON.stringify(file?.lastModifiedDate));
		}
	}, [data, file]);

	// handle pagination
	const handlePageChange = (data) => {
		setPage(data);
	};

	useEffect(() => {
		if (page) {
			navigate(`/?page=${page}&limit=${productLimit}`);
			refetchProducts();
		}
		// eslint-disable-next-line
	}, [page]);

	useEffect(() => {
		if (productsData?.data?.length > 0) {
			setData(productsData?.data);
		}
	}, [productsData]);

	return (
		<>
			{!data?.length ? (
				<div className="container">
					<div className="row">
						<div className="col py-4">
							<FileUploader
								classes="mw-100"
								label="Upload or drop a CSV file right here"
								multiple={false}
								handleChange={handleChange}
								name="file"
								types={['CSV']}
								maxSize={10}
								required
							/>

							<p className="pt-3 text-center text-secondary">
								{file ? `File name: ${file?.name}` : 'No file uploaded, Please upload a CSV file here'}
							</p>
						</div>
					</div>
				</div>
			) : null}

			<div style={{ margin: '20px 0px' }} />

			<div className="hide-container">
				<PrintableProduct ref={printItemsRef} items={selectedItems} />
			</div>

			{data?.length ? (
				<>
					<button
						onClick={handleDownload}
						disabled={isLoadingExportProducts}
						type="button"
						title="Export as CSV"
						className="btn btn-success shadow custom-add-btn"
						style={{
							position: 'fixed',
							bottom: '30px',
							right: '110px',
							zIndex: '999',
							borderRadius: '999px',
							fontWeight: '600',
							fontSize: '12px',
						}}
					>
						<span className="">Export as CSV</span>
					</button>

					{/* ------------------ Search filter ------------------ */}
					<div
						className="container"
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: '30px',
						}}
					>
						<form onSubmit={handleSearchSubmit} className="custom-input-group">
							<input
								type="search"
								className="form-control rounded my-search"
								placeholder="Search for an item"
								aria-label="Search"
								aria-describedby="search-addon"
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
							/>

							<div className="select-container position-relative">
								{/* eslint-disable-next-line react/jsx-no-bind */}
								<MultiSelectDropDown options={productFilterDropdownData} handleSelect={handleFilterSelect} />
								<span
									style={{
										position: 'absolute',
										bottom: '50%',
										right: '15px',
										zIndex: '10',
										transform: 'translateY(50%)',
									}}
								>
									<ChevronDownIcon />
								</span>
							</div>
							<button
								ref={submitBtnRef}
								type="submit"
								className="btn btn-success"
								style={{
									maxWidth: '500px',
									height: 'fit-content',
									minHeight: '30px',
									padding: '0.5rem 1rem',
								}}
							>
								Search
							</button>
						</form>
					</div>

					{productsData?.data?.length > 0 && (
						<div className="container">
							<div className="col">
								<div className="py-3 d-flex justify-content-between align-items-center">
									<div className="select-all-container">
										<button
											type="button"
											className="btn btn-outline-danger text-center p-2 rounded underline"
											title="Delete All Items"
											onClick={handleDeleteAllItems}
										>
											<CgPlayListRemove /> Delete All
										</button>

										<label className="custom-select-single form-check-label" htmlFor="select-all-items">
											<input
												ref={selectAllCheckboxRef}
												className="form-check-input custom-check"
												type="checkbox"
												id="select-all-items"
												name="selectAll"
												onChange={handleSelectAllItemsAtOnce}
												checked={data.filter((product) => product?.isChecked !== true)?.length < 1}
											/>
											<span>Select All</span>
										</label>

										{selectedItems.length > 0 && (
											<span className="my-info">
												{' '}
												({selectedItems.length} item
												{selectedItems.length > 1 ? 's' : ''} selected)
											</span>
										)}
									</div>

									<div className="d-flex gap-2">
										<button
											type="button"
											className="btn btn-outline-danger text-center p-2 rounded"
											title="Delete Selected"
											disabled={!selectedItems?.length}
											onClick={handleShowDeleteProductsModal}
										>
											<AiFillDelete
												title="Delete Selected"
												style={{
													fontSize: '25px',
													cursor: 'pointer',
													textAlign: 'center',
												}}
											/>
										</button>

										<button
											ref={printProductRef}
											type="button"
											onClick={printSelectedItems}
											className="btn btn-success py-2"
											disabled={!selectedItems?.length}
										>
											Print selected products
										</button>
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			) : null}

			{/* TODO: Add new product button and the modal */}
			<div className="container">
				<div className="d-flex justify-content-start">
					<button
						type="button"
						title="Add a new product"
						onClick={openAddProductHandler}
						className="btn btn-success shadow custom-add-btn"
						style={{
							position: 'fixed',
							bottom: '30px',
							right: '30px',
							zIndex: '999',
							borderRadius: '999px',
							fontWeight: '600',
						}}
					>
						<span className="visually-hidden">Add new product</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth={1.5}
							stroke="currentColor"
							style={{
								width: '30px',
							}}
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z"
							/>
						</svg>
					</button>
				</div>
			</div>

			{showModal && (
				<ProductsModal
					showModal={showModal}
					handleClose={handleCloseModal}
					modalData={modalData}
					dataChangeType={dataChangeType}
					submitFormHandler={submitFormHandler}
					isLoading={isLoadingSubmitProduct || isLoadingUpdateProduct}
					isError={isErrorSubmitProduct || isErrorUpdateProduct}
					error={errorSubmitProduct || errorUpdateProduct}
				/>
			)}

			{/* upload progress modal */}
			<DeleteModal showDeleteModal={showBatchUploadModal} hideFooter>
				<div
					className="pt-5"
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{!isErrorSubmitBatchProduct && (
						<>
							<div className={`circle-loader ${batchUploadComplete ? 'load-complete' : ''}`}>
								<div className={`checkmark draw ${batchUploadComplete ? 'show' : ''}`} />
							</div>
							<p
								style={{
									fontSize: '24px',
									marginTop: '-20px',
									color: '#6c757d',
								}}
							>
								{batchUploadComplete ? 'Upload Complete, please wait' : 'Uploading file, please wait'}
							</p>
						</>
					)}

					<p className="pb-3 text-center text-secondary">
						{file ? `File name: ${file?.name}` : 'No file uploaded, Please upload a CSV file here'}
					</p>

					{isErrorSubmitBatchProduct && (
						<div className="w-100">
							<Error error={errorSubmitBatchProduct} />
						</div>
					)}
				</div>
			</DeleteModal>

			{!isLoadingProduct && !isLoadingTicketData && productsData?.totalProducts > 0 ? (
				<div className="container" style={{ minHeight: '65vh' }}>
					<p className="text-end w-100 fw-medium">
						Showing {productsData?.currentProductCount} of {productsData?.totalProducts}
					</p>

					<DeleteModal
						showDeleteModal={showDeleteModal}
						handleCloseDeleteModal={handleCloseDeleteModal}
						handleProductDelete={handleProductDelete}
						isError={isErrorDeleteProduct}
						error={errorDeleteProduct}
						isLoading={isLoadingDeleteProduct}
					>
						<p
							className="text-center mb-0"
							style={{
								fontSize: '25px',
							}}
						>
							Are you sure you want to delete these selected items?
						</p>
					</DeleteModal>

					<DeleteModal
						showDeleteModal={showDeleteProductsModal}
						handleCloseDeleteModal={handleCloseDeleteProductsModal}
						handleProductDelete={handleDeleteProducts}
						isError={isErrorDeleteProducts}
						error={errorDeleteProducts}
						isLoading={isLoadingDeleteProducts}
					>
						<p
							className="text-center mb-0"
							style={{
								fontSize: '25px',
							}}
						>
							Are you sure you want to delete these selected items?
						</p>
					</DeleteModal>

					<DeleteModal
						showDeleteModal={showDeleteAllProductsModal}
						handleCloseDeleteModal={handleCloseDeleteAllProductsModal}
						handleProductDelete={handleDeleteAllProducts}
						isError={isErrorDeleteAllProducts}
						error={errorDeleteAllProducts}
						isLoading={isLoadingDeleteAllProducts}
					>
						<p
							className="text-center mb-0"
							style={{
								fontSize: '25px',
							}}
						>
							Are you sure you want to delete all items?
						</p>
					</DeleteModal>

					<div className="container-fluid p-0">
						<div
							className="row g-3"
							style={{
								minHeight: '30vh',
							}}
						>
							{data &&
								data?.map((product) => (
									<ProductCard
										key={product?.Name}
										product={product}
										handleSelectProductItem={handleSelectProductItem}
										handleShowModal={editProductHandler}
										handleShowDeleteModal={handleShowDeleteModal}
									/>
								))}
						</div>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								marginTop: '40px',
							}}
						>
							{/* total = total page, current = active index, take = no of content per page */}
							<PaginationContainer
								total={productsData?.totalProducts}
								current={page}
								onChangePage={handlePageChange}
								take={Number(productLimit)}
							/>
						</div>
					</div>
				</div>
			) : (
				<div className="container d-flex justify-content-center align-items-center" style={{ minHeight: '65vh' }}>
					<p className="h1 text-secondary">
						{searchQuery ? 'No item found' : `${isFetchedProducts ? 'Upload .CSV file' : 'Loading...'}`}
					</p>
				</div>
			)}
		</>
	);
};

export default UserHomePage;
