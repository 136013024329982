import { getUserRole } from '../utils/localStorage';

export const types = {
	SET_CURRENT_USER_MODE: 'SET_CURRENT_USER_MODE',
	REMOVE_CURRENT_USER_MODE: 'REMOVE_CURRENT_USER_MODE',
};

export const initialState = getUserRole() || '';

// eslint-disable-next-line default-param-last
export const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.SET_CURRENT_USER_MODE:
			// eslint-disable-next-line no-return-assign, no-param-reassign
			return (state = action.payload);

		case types.REMOVE_CURRENT_USER_MODE:
			// eslint-disable-next-line no-return-assign, no-param-reassign, no-unused-vars
			return (state = '');

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
};

export const setCurrentUserMode = (payload = '') => {
	return { type: types.SET_CURRENT_USER_MODE, payload };
};

export const removeCurrentUserMode = () => {
	return { type: types.REMOVE_CURRENT_USER_MODE };
};
