import 'react-grid-layout/css/styles.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-resizable/css/styles.css';
import './TicketTemplate.css';

import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import { DEFAULT_BARCODE_HEIGHT } from '../../../constants/default';
import { DEFAULT_TICKET_MODAL_DELAY_TIME_IN_MS } from '../../../constants/time';
import { defaultPrintProperties, saveToLS, widthConverter } from '../../../utils/helpers';
import isEmpty from '../../../utils/isEmpty';
import PropertiesToPrintContainer from '../PropertiesToPrintContainer/PropertiesToPrintContainer';
import ChangeTicketItemProperty from './ChangeTicketItemProperty';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
// const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);

const TicketTemplate = ({
	layoutWidthSlider = 50,
	setLayoutWidthSlider,
	layoutData = {},
	handleSubmit,
	isLoadingUpdateTicket,
	handleShowDeleteModal,
	templateExist,
	fontSize,
	setFontSize,
	...props
}) => {
	const [layouts, setLayouts] = useState({});
	const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
	// eslint-disable-next-line no-unused-vars
	const [compactType] = useState('vertical'); // or null
	const [mounted, setMounted] = useState(false);
	const [toolbox, setToolbox] = useState({
		lg: [],
	});

	// eslint-disable-next-line no-unused-vars
	const [selectedItem, setSelectedItem] = useState(null);
	const [showTemplateModal, setShowTemplateModal] = useState(false);

	const handleChangeAndSaveFontSize = (e) => {
		setFontSize(Number(e.target.value));
		localStorage.setItem('fontSize', e.target.value);
	};

	// set initial layout
	useEffect(() => {
		if (!isEmpty(layoutData?.layouts?.lg)) {
			setLayouts(layoutData?.layouts);
		} else {
			setLayouts(defaultPrintProperties);
		}
	}, [layoutData]);

	useEffect(() => {
		setMounted(true);
	}, []);

	const onBreakpointChange = (breakpoint) => {
		setCurrentBreakpoint(breakpoint);
		setToolbox({
			...toolbox,
			[breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
		});
	};

	const onLayoutChange = (layout, layouts) => {
		setLayouts({ ...layouts });
		saveToLS('layouts', layouts);
	};

	function handleRemoveItem(pid) {
		const newItems = { lg: _.reject(layouts.lg, { pid }) };
		setLayouts(newItems);
	}

	function onAddItem(title) {
		setLayouts((oldItems) => {
			const newItems = { ...oldItems };

			// Access the last item of the oldItems and get the i value then parse it to integer
			const lastItem = oldItems.lg[oldItems.lg.length - 1];
			const lastItemI = parseInt(lastItem.i, 10);

			newItems.lg.push({
				title,
				pid: title.toLowerCase(),
				x: 2,
				y: 2,
				w: 4,
				h: 2,
				minH: 2,
				i: (lastItemI + 1).toString(),
			});

			return newItems;
		});
	}

	// eslint-disable-next-line no-unused-vars
	const handleSelectItem = (event, index) => {
		event.stopPropagation();
	};

	// eslint-disable-next-line no-unused-vars
	const handleShowTemplateModal = () => setShowTemplateModal(true);
	const handleCloseTemplateModal = () => setShowTemplateModal(false);

	// eslint-disable-next-line no-unused-vars
	// const generateDOM = () => {
	// 	// eslint-disable-next-line func-names
	// 	return _.map(layouts.lg, function (l, i) {
	// 		return (
	// 			// eslint-disable-next-line jsx-a11y/no-static-element-interactions
	// 			<div
	// 				key={i}
	// 				style={{ background: '#E3F4F7', overflow: 'hidden', cursor: 'all-scroll' }}
	// 				className={l.static ? 'static' : ''}
	// 				onMouseDown={(e) => {
	// 					e.stopPropagation();
	// 				}}
	// 				onClick={(event) => handleSelectItem(event, i)}
	// 			>
	// 				<span className="product-container selectable" style={{ fontSize: `${fontSize}px` }} id={`item-${i}`}>
	// 					{l.title || i}
	// 				</span>
	// 			</div>
	// 		);
	// 	});
	// };

	function addPropertyToPrint(checked, value) {
		if (checked) {
			onAddItem(value);
		} else {
			handleRemoveItem(value.toLowerCase());
		}
	}

	// eslint-disable-next-line no-unused-vars
	const handleOnDragStart = (layout, oldItem, newItem, placeholder, event, element) => {
		// remove selected class from other items
		const items = document.querySelectorAll('.product-container-root');
		items.forEach((item) => item.classList.remove('selected'));

		// add selected class to the dragged item
		const selectedElement = document.getElementById(newItem?.i);
		selectedElement.classList.add('selected');

		const filteredItem = layouts.lg.find((item) => item?.i === newItem?.i);
		setSelectedItem(filteredItem);

		setTimeout(() => {
			handleShowTemplateModal();
		}, DEFAULT_TICKET_MODAL_DELAY_TIME_IN_MS);
	};

	return (
		<>
			<div className="template-main-container container-fluid pt-0">
				{/* --------------- Left Grid --------------- */}
				{/* eslint-disable-next-line react/jsx-no-bind */}
				<PropertiesToPrintContainer addPropertyToPrint={addPropertyToPrint} />

				{/* --------------- Right Grid --------------- */}
				<div className="container d-flex flex-column align-items-center position-relative">
					<div className="w-100 d-flex justify-content-end" style={{ gap: '10px' }}>
						{templateExist && (
							<button
								type="button"
								className="btn btn-danger"
								onClick={handleShowDeleteModal}
								disabled={isLoadingUpdateTicket}
							>
								Reset
							</button>
						)}
						<button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={isLoadingUpdateTicket}>
							Save changes
						</button>
					</div>

					<div className="d-flex gap-3">
						{/* --------------- FONT SIZE SLIDER --------------- */}
						<div className="slider-container">
							<div
								className="text-center w-100"
								style={{
									color: '#444',
									fontSize: '16px',
								}}
							>
								Font size ({fontSize} px)
							</div>

							<input
								type="range"
								className="form-range"
								id="font-size-range"
								value={Number(fontSize)}
								defaultValue={Number(fontSize)}
								onChange={handleChangeAndSaveFontSize}
								max={40}
								min={10}
							/>
						</div>

						{/* --------------- TICKET WIDTH SLIDER --------------- */}
						<div className="slider-container">
							<div
								className="text-center w-100"
								style={{
									color: '#444',
									fontSize: '16px',
								}}
							>
								Ticket width ({widthConverter(layoutWidthSlider)} cm)
							</div>

							<input
								type="range"
								className="form-range"
								id="customRange1"
								defaultValue={Number(layoutWidthSlider)}
								onChange={(e) => setLayoutWidthSlider(Number(e.target.value))}
							/>
						</div>
					</div>

					<div className="a4-note">
						<span className="line" />
						<span className="text-sm-custom">21cm</span>
						<span className="line" />
					</div>

					<div
						className="text-sm-custom vertical-text"
						style={{
							position: 'absolute',
							top: '350px',
							right: '-10px',
						}}
					>
						1 x 1 cm
					</div>

					{/* --------------- PAPER --------------- */}
					<div className="paper-container">
						<div className="grid-page" />
						<div
							className="ticket-container"
							style={{
								width: `${widthConverter(layoutWidthSlider)}cm`,
							}}
						>
							<ResponsiveReactGridLayout
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...props}
								style={{ background: '#E7EEF4' }}
								layouts={layouts}
								measureBeforeMount={false}
								useCSSTransforms={mounted}
								compactType="horizontal"
								preventCollision={false}
								onLayoutChange={onLayoutChange}
								onBreakpointChange={onBreakpointChange}
								isDroppable
								autoSize
								isResizable
								resizeHandles={['se', 'sw', 'ne', 'nw']}
								onDragStart={handleOnDragStart}
							>
								{layouts.lg?.map((item, index) => (
									// eslint-disable-next-line jsx-a11y/no-static-element-interactions
									<div
										// eslint-disable-next-line react/no-array-index-key
										key={index}
										id={index}
										style={{ background: '#E3F4F7', overflow: 'hidden', cursor: 'all-scroll' }}
										className={`selectable product-container-root ${item.static ? 'static' : ''}`}
										onMouseDown={(e) => {
											e.stopPropagation();
										}}
									>
										<span className="product-container" style={{ fontSize: `${item?.fontSize || fontSize}px` }}>
											{item?.title || index}
										</span>
									</div>
								))}
							</ResponsiveReactGridLayout>
						</div>
					</div>
				</div>
			</div>

			{showTemplateModal && (
				<ChangeTicketItemProperty
					title="Item Properties"
					showModal={showTemplateModal}
					handleClose={handleCloseTemplateModal}
					layouts={layouts?.lg}
					setLayouts={setLayouts}
					selectedItem={selectedItem}
					defaultFontSize={fontSize}
					defaultHeight={DEFAULT_BARCODE_HEIGHT}
				/>
			)}
		</>
	);
};

export default TicketTemplate;

TicketTemplate.defaultProps = {
	className: 'layout',
	rowHeight: 30,
	// eslint-disable-next-line no-unused-vars
	onLayoutChange: (layout, layouts) => {},
	cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
	breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
	containerPadding: [0, 0],
};
