const Footer = () => {
	return (
		<div className="container py-4">
			<div className="col text-center text-secondary">
				<p>&copy; {new Date().getFullYear()} QPOS365. All rights reserved.</p>
			</div>
		</div>
	);
};

export default Footer;
