import { useEffect, useState } from 'react';

import { productFilterDropdownData } from '../../../utils/productUtils';

const PropertiesToPrintContainer = ({ addPropertyToPrint }) => {
	const [selectedProperties, setSelectedProperties] = useState([]);

	useEffect(() => {
		const props = JSON.parse(localStorage.getItem('rgl-8'));
		const data = props?.layouts?.lg?.map((item) => item.title);
		setSelectedProperties(data?.length ? data : ['Name', 'Barcode']);
	}, []);

	return (
		<div
			className="container d-flex flex-column border-end"
			style={{
				marginTop: '72px',
			}}
		>
			<p
				style={{
					textAlign: 'center',
					color: '#212529',
					borderBottom: '1px dashed #ccc',
					paddingBottom: '16px',
				}}
			>
				<strong>Select properties to add</strong>
			</p>
			<div
				className="row d-flex flex-wrap align-items-center justify-content-between template-checkbox-container"
				style={{
					height: 'fit-content',
				}}
			>
				{selectedProperties?.length > 0 &&
					productFilterDropdownData.map((item) => (
						<label
							key={item.key}
							className={`custom-select-single form-check-label col-sm-6 ${
								item.key.toLowerCase() === 'name' || item.key.toLowerCase() === 'barcode' ? 'disabled' : 'enabled'
							} 
              ${item?.key?.toLowerCase() === 'promo' ? 'bg-danger' : ''}`}
							htmlFor={`select-${item.key}`}
							style={{
								borderRadius: '100px',
								marginBottom: '5px',
								maxWidth: '185px',
								fontSize: '12px',
							}}
						>
							<input
								// ref={selectAllCheckboxRef}
								className={`form-check-input custom-check ${item?.key?.toLowerCase() === 'promo' ? 'text-danger' : ''}`}
								style={{
									minWidth: '14px',
									minHeight: '14px',
								}}
								type="checkbox"
								id={`select-${item.key}`}
								name={`select-${item.key}`}
								onChange={(e) => addPropertyToPrint(e.target.checked, item.cat)}
								defaultChecked={selectedProperties?.includes(item.cat)}
								disabled={item.key.toLowerCase() === 'name' || item.key.toLowerCase() === 'barcode'}
							/>
							<div>{item.cat}</div>
						</label>
					))}

				{/* {selectedProperties?.length > 0 && (
					<label
						className="custom-select-single form-check-label col-sm-6"
						htmlFor="select-unit/price"
						style={{
							borderRadius: '100px',
							marginBottom: '5px',
							maxWidth: '185px',
							fontSize: '12px',
						}}
					>
						<input
							className="form-check-input custom-check"
							style={{
								minWidth: '14px',
								minHeight: '14px',
							}}
							type="checkbox"
							id="select-unit/price"
							name="select-unit/price"
							// onChange={(e) => addPropertyToPrint(e.target.checked, item.cat)}
							// defaultChecked={selectedProperties?.includes(item.cat)}
						/>
						<div>Unit Price</div>
					</label>
				)} */}
			</div>
		</div>
	);
};

export default PropertiesToPrintContainer;
