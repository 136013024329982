/* eslint-disable react/require-default-props */
// eslint-disable-next-line import/no-extraneous-dependencies
import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
const EyeIcon = ({ height = 20, width = 20, className = '' }) => {
	return (
		<svg
			width={height}
			height={width}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox={`0 0 ${24} ${24}`}
			strokeWidth={1.5}
			stroke="#bbb"
		>
			<path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
		</svg>
	);
};

EyeIcon.propTypes = {
	height: PropTypes.number,
	width: PropTypes.number,
	className: PropTypes.string,
};

export default EyeIcon;
