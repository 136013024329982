import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';

import { deleteTicket, getTickets, updateTicket } from '../../apis/ticketsAPIs';
import DeleteModal from '../../components/organisms/DeleteModal';
import TicketTemplateComponent from '../../components/organisms/TicketTemplate/TicketTemplateComponent';

const TicketTemplatePage = () => {
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	// GET - All products
	const {
		data: ticketsData,
		isLoading: isLoadingTicketData,
		refetch: refetchTicket,
	} = useQuery(['tickets'], () => getTickets());

	// PUT - Update Ticket
	const {
		// isError: isErrorUpdateProduct,
		mutateAsync: mutateAsyncUpdateTicket,
		// error: errorUpdateProduct,
		isLoading: isLoadingUpdateTicket,
	} = useMutation((data) => updateTicket(data));

	// DELETE Ticket
	const {
		isError: isErrorDeleteTicket,
		mutateAsync: mutateAsyncDeleteTicket,
		error: errorDeleteTicket,
		isLoading: isLoadingDeleteTicket,
	} = useMutation((templateId) => deleteTicket(templateId));

	// delete modal handlers
	const handleShowDeleteModal = () => {
		setShowDeleteModal(true);
	};

	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false);
	};

	const handleProductDelete = async () => {
		const response = await mutateAsyncDeleteTicket(ticketsData?.data?.ticket?.id);

		if ([200, 201].includes(response.status)) {
			toast.success(response.message);
			handleCloseDeleteModal();

			localStorage.removeItem('rgl-8');
			localStorage.removeItem('ticket_width');
			localStorage.removeItem('fontSize');
			refetchTicket();
			localStorage.setItem('fontSize', 14);
			window.location.reload();
		}
	};

	return (
		<div className="container py-5">
			<DeleteModal
				showDeleteModal={showDeleteModal}
				handleCloseDeleteModal={handleCloseDeleteModal}
				handleProductDelete={handleProductDelete}
				isError={isErrorDeleteTicket}
				error={errorDeleteTicket}
				isLoading={isLoadingDeleteTicket}
			>
				<p
					className="text-center mb-0"
					style={{
						fontSize: '25px',
					}}
				>
					Are you sure you want to reset the template?
				</p>
			</DeleteModal>

			{!isLoadingTicketData && (
				<TicketTemplateComponent
					ticketsData={ticketsData}
					mutateAsyncUpdateTicket={mutateAsyncUpdateTicket}
					isLoadingUpdateTicket={isLoadingUpdateTicket}
					isLoadingTicketData={isLoadingTicketData}
					handleShowDeleteModal={handleShowDeleteModal}
				/>
			)}
		</div>
	);
};

export default TicketTemplatePage;
