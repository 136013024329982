import * as yup from 'yup';

export const validationSchemaProduct = yup.object().shape({
	Name: yup.string().required('Product name is required'),
	ProductGroup: yup.string().optional(),
	SKU: yup.string().required('Product SKU is required'),
	Barcode: yup.array().required('Product barcode is required'),
	MeasurementUnit: yup.string().required('Product measurement unit is required'),
	Cost: yup.string().optional(),
	Markup: yup.string(),
	Price: yup.string().required('Product price is required'),
	Tax: yup.string(),
	IsTaxInclusivePrice: yup.string(),
	IsPriceChangeAllowed: yup.string(),
	IsUsingDefaultQuantity: yup.string(),
	IsService: yup.string(),
	IsEnabled: yup.string(),
	Description: yup.string(),
	Supplier: yup.string(),
	ReorderPoint: yup.string(),
	PreferredQuantity: yup.string(),
	LowStockWarning: yup.string(),
	WarningQuantity: yup.string(),
});
