import Multiselect from 'multiselect-react-dropdown';

const MultiSelectDropDown = ({ options, handleSelect }) => {
	return (
		<Multiselect
			displayValue="key"
			placeholder="Select a filter"
			onKeyPressFn={() => {}}
			onSearch={() => {}}
			onRemove={handleSelect}
			onSelect={handleSelect}
			options={options}
			showCheckbox
		/>
	);
};

export default MultiSelectDropDown;
