import api from './config/axiosInstance';

const ticketsAPIs = {
	async getTickets() {
		try {
			const response = await api.get('/tickets');
			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async updateTicket(data) {
		try {
			const response = await api.put(`/tickets/update`, data);
			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async deleteTicket(id) {
		try {
			const response = await api.delete(`/tickets/${id}`);
			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},
};

export const { getTickets, updateTicket, deleteTicket } = ticketsAPIs;

export default ticketsAPIs;
