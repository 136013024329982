import { productGroupEnum } from './productUtils';

export const initialProducts = {
	Name: '',
	Weight: '',
	Brand: '',
	ProductGroup: '',
	SKU: '',
	Barcode: '',
	MeasurementUnit: '',
	Cost: '',
	Markup: '',
	Price: '',
	Tax: '',
	IsTaxInclusivePrice: '',
	IsPriceChangeAllowed: '',
	IsUsingDefaultQuantity: '',
	IsService: '',
	IsEnabled: '',
	Description: '',
	Supplier: '',
	ReorderPoint: '',
	PreferredQuantity: '',
	LowStockWarning: '',
	WarningQuantity: '',
};

export const productFormInputs = [
	{
		name: 'Name',
		type: 'text',
	},
	{
		name: 'Weight',
		type: 'text',
	},
	{
		name: 'Brand',
		type: 'text',
	},
	{
		name: 'ProductGroup',
		type: 'text',
		placeholder: `ex- ${productGroupEnum}`,
	},
	{
		name: 'SKU',
		type: 'number',
	},
	{
		name: 'Barcode',
		type: 'text',
	},
	{
		name: 'MeasurementUnit',
		type: 'text',
	},
	{
		name: 'Cost',
		type: 'number',
	},
	{
		name: 'Markup',
		type: 'number',
	},
	{
		name: 'Price',
		type: 'number',
	},
	{
		name: 'Tax',
		type: 'number',
	},
	{
		name: 'IsTaxInclusivePrice',
		type: 'number',
	},
	{
		name: 'IsPriceChangeAllowed',
		type: 'number',
	},
	{
		name: 'IsUsingDefaultQuantity',
		type: 'number',
	},
	{
		name: 'IsService',
		type: 'number',
	},
	{
		name: 'IsEnabled',
		type: 'number',
	},
	{
		name: 'Description',
		type: 'textarea',
	},
	{
		name: 'Supplier',
		type: 'text',
	},
	{
		name: 'ReorderPoint',
		type: 'text',
	},
	{
		name: 'PreferredQuantity',
		type: 'number',
	},
	{
		name: 'LowStockWarning',
		type: 'number',
	},
	{
		name: 'WarningQuantity',
		type: 'number',
	},
];
