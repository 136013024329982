const initialValuesProduct = (defaultValue) => {
	const initialValues = {};

	Object.keys(defaultValue).forEach((objectKey) => {
		initialValues[objectKey] = defaultValue[objectKey] ?? '';
	});

	return initialValues;
};

export default initialValuesProduct;
