const NotApprovePage = ({ result }) => {
	return (
		<div className="container mt-5">
			<div className="row">
				<div className="col-md-6 mx-auto">
					<div className="alert alert-secondary text-center d-flex flex-column gap-3">
						<h1 className="display-6">{result === 'REJECTED' ? 'You Are Rejected' : 'Not Approved Yet'}</h1>
						<p className="lead">
							{result === 'REJECTED'
								? 'Sorry, your account has been rejected by the admin.'
								: 'Sorry, your account has not been approved by the admin yet.'}
						</p>
						<p>Please contact the administrator for further assistance.</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default NotApprovePage;
