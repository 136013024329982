import api from './config/axiosInstance';

const usersAPIs = {
	async getUsers() {
		try {
			const response = await api.get('/admins/users');
			return response.data;
		} catch (error) {
			return error?.response?.data;
		}
	},

	async deleteUser(id) {
		try {
			const response = await api.delete(`/admins/users/${id}`);
			return response.data;
		} catch (error) {
			return error?.response;
		}
	},

	async approvedByAdmin(id) {
		try {
			const response = await api.put(`/admins/approve/${id}`);
			return response.data;
		} catch (error) {
			return error?.response;
		}
	},

	async rejectedByAdmin(id) {
		try {
			const response = await api.put(`/admins/reject/${id}`);
			return response.data;
		} catch (error) {
			return error?.response;
		}
	},

	async registerOrSignUp(data) {
		try {
			const response = await api.post('/users/register', data);

			if ([200, 201].includes(response.status)) {
				const { firstName, lastName, email, isApprovedByAdmin } = response?.data?.data || {};
				localStorage.setItem('user-details', JSON.stringify({ firstName, lastName, email, isApprovedByAdmin }));
			}

			return response?.data;
		} catch (error) {
			return error?.response;
		}
	},

	async signIn(data) {
		try {
			const response = await api.post('/users/login', data);

			if ([200, 201].includes(response?.status)) {
				const { firstName, lastName, email, role, isApprovedByAdmin, token } = response?.data?.data || {};
				localStorage.setItem('user-details', JSON.stringify({ firstName, lastName, email, isApprovedByAdmin }));
				localStorage.setItem('token', JSON.stringify(token));
				localStorage.setItem('role', JSON.stringify(role));
			}

			return response?.data;
		} catch (error) {
			return error?.response;
		}
	},

	async getUserDetailsById(id) {
		try {
			const response = await api.get(`/users/${id}`);

			if ([200, 201].includes(response?.status)) {
				return response?.data;
			}

			return response?.data;
		} catch (error) {
			return error?.response;
		}
	},
};

export const { getUsers, registerOrSignUp, signIn, deleteUser, approvedByAdmin, rejectedByAdmin, getUserDetailsById } =
	usersAPIs;

export default usersAPIs;
