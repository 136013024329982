import { Navigate, Route, Routes } from 'react-router-dom';

import useAuth from '../hooks/useAuth';

const AuthenticatedAndNotProtectedRoute = ({ children = null, redirectTo = '/signin' }) => {
	const { state: isAuthenticated } = useAuth();
	return isAuthenticated ? children : <Navigate to={redirectTo} />;
};

const UnAuthenticated = ({ children = null, redirectTo = '/' }) => {
	const { state: isAuthenticated } = useAuth();
	return !isAuthenticated ? children : <Navigate to={redirectTo} />;
};

export const routeConfig = (routes) => {
	if (!routes) return <div />;

	window.scrollTo(0, 0);

	return (
		<Routes>
			{routes?.map((route) => (
				<Route
					key={route.name}
					path={route.path}
					element={
						route?.protected ? (
							<AuthenticatedAndNotProtectedRoute>
								<route.component />
							</AuthenticatedAndNotProtectedRoute>
						) : (
							<UnAuthenticated>
								<route.component />
							</UnAuthenticated>
						)
					}
				/>
			))}
		</Routes>
	);
};
