import * as Yup from 'yup';
// import { validatePhoneNumber } from "../../../validation/phone";

export const validationSchema = Yup.object({
	firstName: Yup.string().min(2).max(25).required('Please enter your first name'),
	lastName: Yup.string().min(2).max(25).required('Please enter your last name'),
	email: Yup.string().email().required('Please enter your email'),
	phone: Yup.string().required('Phone number is required'),
	password: Yup.string().min(6).required('Please enter your password'),
	confirm_password: Yup.string()
		.required('Please enter your confirm password')
		.oneOf([Yup.ref('password'), null], 'Password must match'),
});
