const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	password: '',
	confirm_password: '',
};

export default initialValues;
