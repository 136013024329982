import { Form, Formik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import { signIn } from '../../apis/usersAPIs';
import Logo from '../../assets/images/qpos-logo.png';
import EmailIcon from '../../assets/svgs/EmailIcon';
import LockIcon from '../../assets/svgs/LockIcon';
import InputField from '../../components/atoms/InputField';
import LoadingButtonWithTitle from '../../components/molecules/LoadingButtonWithTitle';
import useAuth from '../../hooks/useAuth';
import useCurrentUserRole from '../../hooks/useCurrentUserRole';
import { setAuthToken } from '../../reducers/authReducer';
import { setCurrentUserMode } from '../../reducers/userRoleReducer';
import initialValues from './validation/initialValues';
import { validationSchema } from './validation/validationSchema';

const SignInPage = () => {
	const [showPassword, setShowPassword] = useState(false);

	const navigateTo = useNavigate();
	const { isLoading, mutateAsync } = useMutation((data) => signIn(data));
	const { dispatch } = useAuth();
	const { dispatch: roleDispatch } = useCurrentUserRole();

	// eslint-disable-next-line consistent-return
	const handleSubmit = async (data) => {
		const response = await mutateAsync(data);

		if ([200, 201].includes(response?.status) && response?.data?.token) {
			dispatch(setAuthToken(response?.data?.token));
			roleDispatch(setCurrentUserMode(response?.data?.role));
			toast.success('Successfully logged in.');
			// eslint-disable-next-line no-unused-expressions
			response?.data?.role === 'USER' ? navigateTo('/') : navigateTo('/users');
		}

		if ([400, 404, 500].includes(response?.status)) {
			toast.error('Your email/password incorrect, Try again.');
			// Failure code blocks
		}
	};

	return (
		<div className="container mt-5 mb-3 d-flex justify-content-center">
			<div className="card px-1 py-3">
				<div className="card-body">
					<div className="d-flex justify-content-center">
						<img
							style={{
								height: '29px',
							}}
							src={Logo}
							alt="QPOC Logo"
						/>
					</div>

					<div className="py-4">
						<h6 className="information mt-4 text-center">Welcome Back, Please login to your account.</h6>
					</div>

					<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
						<fieldset disabled={isLoading}>
							<Form className="row">
								<div className="col">
									<InputField
										icon={<EmailIcon className="w-4 h-5" />}
										name="email"
										type="email"
										placeholder="Email address"
									/>

									<InputField
										icon={<LockIcon className="w-4 h-5" />}
										name="password"
										type={showPassword ? 'text' : 'password'}
										placeholder="Password"
										showPassword={showPassword}
										setShowPassword={setShowPassword}
										showicon
									/>
									<button
										className="w-100 rounded-2 py-2 text-white"
										type="submit"
										style={{
											fontWeight: '600',
											background: '#1CC5DC',
											border: 'none',
										}}
									>
										<LoadingButtonWithTitle title="Log in" loading={isLoading} />
									</button>
								</div>
							</Form>
						</fieldset>
					</Formik>

					<p className="text-center mt-5" style={{ color: '#828B9E' }}>
						Don&apos;t have an account?
						<Link to="/signup">
							<span className="mx-1" style={{ fontWeight: '600', color: '#1CC5DC' }}>
								Join Now
							</span>
						</Link>
					</p>
				</div>
			</div>
		</div>
	);
};

export default SignInPage;
