import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { approvedByAdmin, deleteUser, getUsers, rejectedByAdmin } from '../../apis/usersAPIs';

const UserLists = () => {
	const queryClient = useQueryClient();
	const { data: users } = useQuery(['users'], getUsers);
	const {
		// isError: isUserDeleteError,
		// isLoading: isUserDeleteLoading,
		// error: userDeleteError,
		mutateAsync: userDeleteMutateAsync,
	} = useMutation((data) => deleteUser(data), {
		onSuccess: () => queryClient.invalidateQueries(['users']),
	});

	const {
		// isLoading: isUserApprovedLoading,
		mutateAsync: userApprovedMutateAsync,
	} = useMutation((id) => approvedByAdmin(id), {
		onSuccess: () => queryClient.invalidateQueries(['users']),
	});

	const {
		// isLoading: isUserApprovedLoading,
		mutateAsync: userRejectedMutateAsync,
	} = useMutation((id) => rejectedByAdmin(id), {
		onSuccess: () => queryClient.invalidateQueries(['users']),
	});

	const deleteUserHandler = async (id) => {
		const response = await userDeleteMutateAsync(id);
		if (response?.success) {
			toast.success('User are successfully deleted!');
		}
	};

	const approvedUserHandler = async (id) => {
		const response = await userApprovedMutateAsync(id);
		if (response?.success) {
			toast.success('User are approved!');
		}
	};

	const rejectedUserHandler = async (id) => {
		const response = await userRejectedMutateAsync(id);
		if (response?.success) {
			toast.success('User are Rejected!');
		}
	};

	return (
		<div className="container">
			<div className="row">
				<div className="px-4">
					<div className="table-responsive min-vh-100 mt-5">
						<table className="table table-hover table-sm table-border">
							<thead>
								<tr>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<th />
									<th scope="col">First Name</th>
									<th scope="col">Last Name</th>
									<th scope="col">Email</th>
									<th scope="col">Phone</th>
									<th scope="col">Created At</th>
									<th scope="col">Status</th>
								</tr>
							</thead>

							<tbody>
								{users?.data?.map((user, index) => (
									<tr key={user?.id}>
										<th className="py-3 text-start" scope="row">
											{index + 1}
										</th>
										<td className="py-3 text-start">{user?.firstName}</td>
										<td className="py-3 text-start">{user?.lastName}</td>
										<td className="py-3 text-start">{user?.email}</td>
										<td className="py-3 text-start">{user?.phone}</td>
										<td className="py-3 text-start">{user?.createdAt?.split('T')?.[0]}</td>
										<td className="py-3 text-start">
											<div className="d-flex justify-content-center align-items-center gap-1">
												<div
													className={`${
														// eslint-disable-next-line no-nested-ternary
														user?.isApprovedByAdmin === 'UNASSIGNED'
															? 'bg-secondary'
															: // eslint-disable-next-line no-nested-ternary
															user?.isApprovedByAdmin === 'APPROVED'
															? 'bg-success'
															: user?.isApprovedByAdmin === 'REJECTED'
															? 'bg-warning'
															: 'bg-danger'
													}`}
													style={{
														width: '13px',
														height: '12px',
														borderRadius: '50%',
														display: 'flex',
														alignItems: 'center',
														justifyContent: 'center',
														fontSize: '20px',
													}}
												/>

												<select
													className="form-select"
													onChange={(e) => {
														if (e.target.value === 'APPROVED') {
															approvedUserHandler(user?.id);
														} else if (e.target.value === 'REJECTED') {
															rejectedUserHandler(user?.id);
														} else if (e.target.value === 'DELETE') {
															deleteUserHandler(user?.id);
														}
													}}
													value={user?.isApprovedByAdmin}
												>
													<option value="UNASSIGNED">None</option>
													<option value="APPROVED">Approved</option>
													<option value="REJECTED">Rejected</option>
													<option value="DELETE">Deleted</option>
												</select>
											</div>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserLists;

/**
<td className="d-flex flex-column flex-md-row gap-3 py-3">
    <button
      onClick={() => approvedUserHandler(user?.id)}
      type="button"
      className="btn btn-primary btn-sm fw-bold sm"
      style={{ fontSize: '10px' }}
      disabled={
        user &&
        user?.isApprovedByAdmin !== 'UNASSIGNED' &&
        (user?.isApprovedByAdmin === 'APPROVED' || user?.isApprovedByAdmin === 'REJECTED')
      }
    >
      Approve
    </button>
    <button
      onClick={() => rejectedUserHandler(user?.id)}
      type="button"
      className="btn btn-warning btn-sm fw-bold"
      style={{ fontSize: '10px' }}
      disabled={
        user &&
        user?.isApprovedByAdmin !== 'UNASSIGNED' &&
        (user?.isApprovedByAdmin === 'APPROVED' || user?.isApprovedByAdmin === 'REJECTED')
      }
    >
      Reject
    </button>
    <span className="text-center text-danger">
      {' '}
      <AiFillDelete
        onClick={() => deleteUserHandler(user?.id)}
        style={{
          fontSize: '20px',
          cursor: 'pointer',
          textAlign: 'center',
        }}
      />
    </span>
  </td>
 */
