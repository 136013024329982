import { Form, Formik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';

import { registerOrSignUp } from '../../apis/usersAPIs';
import Logo from '../../assets/images/qpos-logo.png';
import EmailIcon from '../../assets/svgs/EmailIcon';
import LockIcon from '../../assets/svgs/LockIcon';
import PhoneIcon from '../../assets/svgs/PhoneIcon';
import UserIcon from '../../assets/svgs/UserIcon';
import Error from '../../components/atoms/Error';
import InputField from '../../components/atoms/InputField';
import LoadingButtonWithTitle from '../../components/molecules/LoadingButtonWithTitle';
import initialValues from './validation/initialValues';
import { validationSchema } from './validation/validationSchema';

const SignUp = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [isError, setIsError] = useState(false);
	const [error, setError] = useState('');

	const navigateTo = useNavigate();
	const { isLoading, mutateAsync } = useMutation((data) => registerOrSignUp(data));

	// eslint-disable-next-line consistent-return
	const handleSubmit = async (data) => {
		setIsError(false);
		setError('');

		const { firstName, lastName, email, phone, password } = data;
		const formData = { firstName, lastName, email, phone, password };
		const response = await mutateAsync(formData);

		if ([200, 201].includes(response?.status)) {
			toast.success('Successfully signed up.');
			return navigateTo('/signin');
		}

		if ([400, 404, 500].includes(response?.status)) {
			setIsError(true);
			setError(response?.data?.message);
			toast.error('Provided incorrect informations, Please try again.');
		}
	};

	return (
		<div className="container mt-5 mb-3 d-flex justify-content-center">
			<div className="card px-1 py-3">
				<div className="card-body">
					<div className="d-flex justify-content-center">
						<img
							style={{
								height: '29px',
							}}
							src={Logo}
							alt="QPOC Logo"
						/>
					</div>

					<div className="pt-4 pb-3">
						<h6 className="information mt-4 text-center">Welcome Back, Please create your account.</h6>
					</div>

					{isError && <Error error={error} />}

					<Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
						<fieldset disabled={isLoading}>
							<Form className="row mt-1">
								<div className="col">
									<InputField
										icon={<UserIcon className="w-4 h-5" />}
										type="text"
										name="firstName"
										placeholder="Please enter your first name"
									/>
									<InputField
										icon={<UserIcon className="w-4 h-5" />}
										type="text"
										name="lastName"
										placeholder="Please enter your last name"
									/>

									<InputField
										icon={<EmailIcon className="w-4 h-5" />}
										name="email"
										type="email"
										placeholder="Email address"
									/>
									<InputField
										icon={<PhoneIcon className="w-4 h-5" />}
										name="phone"
										type="phone"
										placeholder="Phone number"
									/>
									<InputField
										icon={<LockIcon className="w-4 h-5" />}
										placeholder="Enter your password"
										name="password"
										type={showPassword ? 'text' : 'password'}
										showPassword={showPassword}
										setShowPassword={setShowPassword}
										showcon
									/>
									<InputField
										icon={<LockIcon className="w-4 h-5" />}
										placeholder="Please enter confirm password"
										name="confirm_password"
										type={showConfirmPassword ? 'text' : 'password'}
										showPassword={showConfirmPassword}
										setShowPassword={setShowConfirmPassword}
										showicon
									/>

									<button
										className="w-100 rounded-2 py-2 text-white"
										type="submit"
										style={{
											fontWeight: '600',
											background: '#1CC5DC',
											border: 'none',
										}}
									>
										<LoadingButtonWithTitle title="Create" />
									</button>
								</div>
							</Form>
						</fieldset>
					</Formik>

					<div className=" text-center px-5 mt-2 mb-0">
						<small className="agree-text">Already have an account?</small>{' '}
						<Link to="/signin">
							<span className="mx-1" style={{ fontWeight: '600', color: '#1CC5DC' }}>
								Log In
							</span>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
