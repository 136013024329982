import { useState } from 'react';

import { DEFAULT_BARCODE_HEIGHT } from '../../../constants/default';
import { saveToLS } from '../../../utils/helpers';
import ModalReuseable from '../../ModalReuseable';

// eslint-disable-next-line no-unused-vars
const ChangeTicketItemProperty = ({
	defaultFontSize = 14,
	defaultHeight = 60,
	// eslint-disable-next-line no-unused-vars
	layouts = {},
	setLayouts = null,
	selectedItem = {},
	...props
}) => {
	const [fontSize, setFontSize] = useState(selectedItem?.fontSize || defaultFontSize || 14);
	// eslint-disable-next-line no-unused-vars
	const [height, setHeight] = useState(selectedItem?.height || defaultHeight || DEFAULT_BARCODE_HEIGHT);
	const [customValue, setCustomValue] = useState(selectedItem?.value || '');

	const handleChangeAndSaveFontSize = (e) => {
		setFontSize(Number(e.target.value));
	};

	const handleCustomValueChange = (e) => {
		setCustomValue(e.target.value);
	};

	const handleChangeAndSaveBarcodeHeight = (e) => {
		setHeight(Number(e.target.value));
	};

	const handleTemplateSubmit = () => {
		// set selected item to the dragged item for further actions
		setLayouts((oldItems) => {
			const newItems = { ...oldItems };
			newItems.lg = newItems.lg.map((item) => {
				if (item?.i === selectedItem?.i) {
					return {
						...item,
						fontSize,
						height,
						value: customValue,
					};
				}
				return item;
			});

			saveToLS('layouts', newItems);
			return newItems;
		});

		props?.handleClose();
	};

	return (
		// eslint-disable-next-line react/jsx-props-no-spreading
		<ModalReuseable {...props} handleSubmit={handleTemplateSubmit}>
			<div className="container">
				<div className="row g-3">
					{selectedItem?.pid === 'customlabel' && (
						<div className="col-md-12">
							<div className="d-flex align-items-center justify-content-center">
								<div className="" style={{ minWidth: '500px' }}>
									<label
										htmlFor="customlabel"
										className="text-center w-100 form-label"
										style={{
											color: '#444',
											fontSize: '16px',
										}}
									>
										Provide custom value
										<input
											type="text"
											className="form-control mt-2"
											id="customlabel"
											value={customValue}
											onChange={handleCustomValueChange}
										/>
									</label>
								</div>
							</div>
						</div>
					)}

					<div className="col-md-12">
						<div className="d-flex align-items-center justify-content-center">
							<div className="" style={{ minWidth: '500px' }}>
								<div
									className="text-center w-100"
									style={{
										color: '#444',
										fontSize: '16px',
									}}
								>
									Font size ({fontSize} px)
								</div>

								<input
									type="range"
									className="form-range"
									id="font-size-range"
									value={Number(fontSize)}
									defaultValue={Number(fontSize)}
									onChange={handleChangeAndSaveFontSize}
									max={40}
									min={10}
								/>
							</div>
						</div>
					</div>

					{selectedItem?.pid === 'barcode' && (
						<div className="col-md-12">
							<div className="d-flex align-items-center justify-content-center">
								<div className="" style={{ minWidth: '500px' }}>
									<div
										className="text-center w-100"
										style={{
											color: '#444',
											fontSize: '16px',
										}}
									>
										Barcode Height ({height} px)
									</div>

									<input
										type="range"
										className="form-range"
										id="barcode-height-range"
										value={Number(height)}
										defaultValue={Number(height)}
										onChange={handleChangeAndSaveBarcodeHeight}
										max={150}
										min={20}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</ModalReuseable>
	);
};

export default ChangeTicketItemProperty;
