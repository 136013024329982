import { useContext } from 'react';

import { UserRoleContext } from '../contexts/UserRoleContext';

const useCurrentUserRole = () => {
	const context = useContext(UserRoleContext);

	if (context === undefined) {
		throw new Error('useCurrentUserRole must be used within a AuthProvider');
	}

	return context;
};

export default useCurrentUserRole;
