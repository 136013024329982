import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import TicketTemplate from './TicketTemplate';

const TicketTemplateComponent = ({
	ticketsData = {},
	mutateAsyncUpdateTicket,
	isLoadingUpdateTicket = false,
	isLoadingTicketData = false,
	handleShowDeleteModal,
}) => {
	const [layoutWidthSlider, setLayoutWidthSlider] = useState(localStorage.getItem('ticket_width'));
	const [fontSize, setFontSize] = useState(14);

	// get initial ticket width
	useEffect(() => {
		const ticketWidth = Number(ticketsData?.data?.ticket?.ticketWidth || 50);
		if (ticketWidth) {
			setLayoutWidthSlider(ticketWidth);
		}
	}, [ticketsData]);

	useEffect(() => {
		const fontSizeFromStorage = localStorage.getItem('fontSize');
		if (fontSizeFromStorage) {
			setFontSize(Number(fontSizeFromStorage));
		}
	}, []);

	// form submission
	const handleTemplateSubmit = async () => {
		localStorage.setItem('ticket_width', layoutWidthSlider);
		const layoutFromStorage = localStorage.getItem('rgl-8');

		if (layoutFromStorage) {
			const ticketData = {
				ticketWidth: layoutWidthSlider.toString(),
				ticketLayout: layoutFromStorage,
			};

			const response = await mutateAsyncUpdateTicket(ticketData);
			if (response.success) {
				toast.success(response.message);
				window.location.reload();
			}
		}
	};

	return (
		<>
			{!isLoadingTicketData ? (
				<TicketTemplate
					layoutData={
						ticketsData?.data?.ticket?.ticketLayout ? JSON.parse(ticketsData?.data?.ticket?.ticketLayout) : {}
					}
					templateExist={ticketsData?.data?.ticket}
					layoutWidthSlider={layoutWidthSlider}
					setLayoutWidthSlider={setLayoutWidthSlider}
					handleSubmit={handleTemplateSubmit}
					isLoadingUpdateTicket={isLoadingUpdateTicket}
					handleShowDeleteModal={handleShowDeleteModal}
					fontSize={fontSize}
					setFontSize={setFontSize}
				/>
			) : null}

			{/* ticket template modal */}
			{/* <ModalReuseable
        isLoading={isLoadingUpdateTicket}
        title="Change Template Design"
        showModal={showTemplateModal}
        handleClose={handleCloseTemplateModal}
        handleSubmit={handleTemplateSubmit}
      >
        
      </ModalReuseable> */}
		</>
	);
};

export default TicketTemplateComponent;
