import { useField } from 'formik';

const TextAreaInput = ({ placeholder = '', name = '', label = '' }) => {
	const [field, meta] = useField({ name });

	return (
		<div className="mb-4">
			{label && (
				<label htmlFor={name} className="text-start mb-1" style={{ fontSize: '14px', fontWeight: '500' }}>
					{label}
				</label>
			)}

			<textarea
				className="form-control border"
				name={name}
				placeholder={placeholder}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...field}
				rows="3"
				style={{
					border: 'none',
					outline: 'none',
					fontSize: '14px',
					fontWeight: '500',
					background: 'rgb(245, 246, 250)',
					paddingLeft: '10px',
					boxShadow: 'none',
				}}
			/>

			{meta.touched && meta.error ? (
				<div className="error text-start" style={{ color: '#DC2626', fontSize: '14px', fontWeight: '500' }}>
					{meta.error}
				</div>
			) : null}
		</div>
	);
};

export default TextAreaInput;
